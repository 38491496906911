export const DeliveryMovingIconCopperStudio = (props: any) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.94 8.55L20.89 7.5C20.3896 6.99399 19.7115 6.70335 19 6.69H15V6.11C15 4.59122 13.7688 3.36 12.25 3.36H4C3.58579 3.36 3.25 3.69579 3.25 4.11C3.25 4.52421 3.58579 4.86 4 4.86H12.22C12.9104 4.86 13.47 5.41964 13.47 6.11V12.44C13.47 12.8542 13.8058 13.19 14.22 13.19C14.6342 13.19 14.97 12.8542 14.97 12.44V8.19H18.97C19.3014 8.18869 19.6191 8.32226 19.85 8.56L20.9 9.56C21.0432 9.70847 21.1464 9.89081 21.2 10.09H17C16.5858 10.09 16.25 10.4258 16.25 10.84C16.25 11.2542 16.5858 11.59 17 11.59H21.25V15.84C21.2636 16.1801 21.138 16.511 20.902 16.7564C20.6661 17.0017 20.3404 17.1403 20 17.14H18.89C18.6439 15.9862 17.6247 15.1615 16.445 15.1615C15.2653 15.1615 14.2461 15.9862 14 17.14H8.66C8.39085 15.9474 7.29272 15.1289 6.07293 15.2117C4.85315 15.2944 3.87567 16.2538 3.77011 17.4718C3.66454 18.6899 4.4624 19.8031 5.64975 20.0945C6.83711 20.3859 8.05968 19.7685 8.53 18.64H14.14C14.5298 19.5682 15.4383 20.172 16.445 20.172C17.4517 20.172 18.3602 19.5682 18.75 18.64H20C21.5188 18.64 22.75 17.4088 22.75 15.89V10.5C22.7514 9.76789 22.4597 9.06568 21.94 8.55ZM6.22 18.48C5.88428 18.4964 5.57256 18.3062 5.43351 18.0002C5.29446 17.6942 5.35624 17.3343 5.58939 17.0922C5.82254 16.8501 6.17983 16.7748 6.49087 16.9022C6.80191 17.0296 7.0037 17.3339 7 17.67C7.0003 18.1059 6.65559 18.4639 6.22 18.48ZM15.6815 17.9714C15.8078 18.2791 16.1074 18.48 16.44 18.48C16.889 18.48 17.2545 18.119 17.26 17.67C17.2641 17.3375 17.0669 17.0354 16.7608 16.9053C16.4547 16.7753 16.1004 16.8429 15.8638 17.0766C15.6272 17.3103 15.5552 17.6638 15.6815 17.9714Z'
      fill='currentColor'
    />
    <path
      d='M10.75 9C10.7446 8.58804 10.412 8.25539 10 8.25H2C1.58579 8.25 1.25 8.58579 1.25 9C1.25 9.41421 1.58579 9.75 2 9.75H10C10.412 9.74461 10.7446 9.41196 10.75 9Z'
      fill='currentColor'
    />
    <path
      d='M10 11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H10C10.4142 12.75 10.75 12.4142 10.75 12C10.75 11.5858 10.4142 11.25 10 11.25Z'
      fill='currentColor'
    />
  </svg>
)
