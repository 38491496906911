export const LoupeIcon = ({ ...props }) => (
  <svg
    role='img'
    width={24}
    height={24}
    viewBox='0 0 26 27'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Search Icon</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.2202 10.5391C19.2202 15.5096 15.1908 19.5391 10.2202 19.5391C5.24965 19.5391 1.22021 15.5096 1.22021
      10.5391C1.22021 5.5685 5.24965 1.53906 10.2202 1.53906C15.1908 1.53906 19.2202 5.5685 19.2202 10.5391ZM16.549
      18.2819C14.8251 19.6927 12.6215 20.5391 10.2202 20.5391C4.69737 20.5391 0.220215 16.0619 0.220215 10.5391C0.220215
      5.01621 4.69737 0.539062 10.2202 0.539062C15.7431 0.539062 20.2202 5.01621 20.2202 10.5391C20.2202 13.3004 19.101
      15.8004 17.2914 17.6101L25.0598 25.3785C25.26 25.5787 25.264 25.8993 25.0688 26.0946C24.8735 26.2898 24.5529
      26.2858 24.3527 26.0856L16.549 18.2819Z'
    />
  </svg>
)
