'use client'

import { createContext, FC, PropsWithChildren, useContext } from 'react'
import { NormalizedMenuTaxon } from '@models'
import { normalizeTaxonsForMenu } from '@components/common/UserNav/MenuSidebarView/normalizeTaxonsForMenu'
import { requireConfigValue } from '@framework/isomorphic-config'
import useSWR from 'swr'

export const TaxonsContext = createContext({} as NormalizedMenuTaxon[])

TaxonsContext.displayName = 'TaxonsContext'

export const getTaxons = async (
  url: string,
): Promise<NormalizedMenuTaxon[]> => {
  const params = new URLSearchParams({
    'filter[depth]': '1',
    'filter[with_stock]': 'true',
    include: 'children,image',
  })

  const res = await fetch(`${url}?${params}`).then(result => result.json())

  return normalizeTaxonsForMenu(res)
}

const defaultTaxon = [] as NormalizedMenuTaxon[]

export const TaxonsProvider = (props: PropsWithChildren) => {

  const { data } = useSWR(`${process.env.NEXT_PUBLIC_SPREE_API_HOST}/api/v2/storefront/taxons`, getTaxons)

  return <TaxonsContext.Provider value={data || defaultTaxon} {...props} />
}

export const useTaxonsContext = () => {
  const context = useContext<NormalizedMenuTaxon[]>(TaxonsContext)

  if (context === undefined) {
    throw new Error(
      'useStoreDataContext must be used within a StoreDataProvider',
    )
  }

  return context
}
