import { FacebookIcon } from '@components/iconSetCopperStudio/FacebookIcon'
import { InstagramIcon } from '@components/iconSetCopperStudio/InstagramIcon'
import s from '../Footer.module.css'

export const StayConnected = ({ isSmall }: { isSmall: boolean }) => (
  <div
    className={`${
      s.footerNavColumn
    } lg:mb-0 sm:pb-0 lg:w-1/3 md:w-2/6 sm:w-1/2 w-full sm:px-4 sm:mt-[70px] ${
      isSmall ? '!inline-flex sm:!hidden' : '!hidden sm:!inline-flex '
    } `}
  >
    <span data-testid='categories' className={s.footerTitle}>
      Stay connected
    </span>

    <div className='flex gap-2 items-center'>
      <a
        href='https://www.facebook.com/copperstudioofficial/'
        target='_blank'
        rel='noreferrer'
      >
        <FacebookIcon />
      </a>
      <a
        href='https://www.instagram.com/copperstudioofficial/'
        target='_blank'
        rel='noreferrer'
      >
        <InstagramIcon />
      </a>
    </div>
  </div>
)
