export const CartNinefit = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.23737 2.28869C1.84442 2.15771 1.41968 2.37008 1.28869 2.76303C1.15771 3.15599 1.37008 3.58073 1.76303 3.71172L2.02794 3.80002C2.70435 4.02549 3.15155 4.17575 3.481 4.32901C3.79296 4.47414 3.92784 4.59093 4.01426 4.71083C4.10068 4.83074 4.16883 4.99562 4.20785 5.33747C4.24907 5.69848 4.2502 6.17024 4.2502 6.88324L4.2502 9.55508C4.25018 10.9227 4.25017 12.025 4.36673 12.892C4.48774 13.7921 4.74664 14.5499 5.34855 15.1519C5.95047 15.7538 6.70834 16.0127 7.60845 16.1337C8.47541 16.2502 9.57773 16.2502 10.9453 16.2502H19.0002C19.4144 16.2502 19.7502 15.9144 19.7502 15.5002C19.7502 15.086 19.4144 14.7502 19.0002 14.7502H11.0002C9.56479 14.7502 8.56367 14.7486 7.80832 14.6471C7.07455 14.5484 6.68598 14.368 6.40921 14.0912C6.31252 13.9945 6.22758 13.8842 6.15378 13.7502H16.0587C16.507 13.7502 16.9014 13.7503 17.2288 13.7149C17.5832 13.6766 17.9266 13.5916 18.2497 13.3786C18.5728 13.1656 18.7862 12.8834 18.961 12.5727C19.1224 12.2857 19.2778 11.9232 19.4544 11.5111L19.9212 10.4219C20.3057 9.52486 20.6273 8.77441 20.7883 8.16406C20.9563 7.52731 21 6.8625 20.6038 6.2616C20.2076 5.66069 19.5793 5.43901 18.9278 5.34257C18.3034 5.25013 17.4869 5.25016 16.511 5.2502L5.70696 5.2502C5.70421 5.22221 5.70129 5.19459 5.69817 5.16732C5.64282 4.6825 5.52222 4.23764 5.23112 3.83377C4.94002 3.42989 4.55613 3.1748 4.1137 2.96898C3.69746 2.77534 3.16814 2.59892 2.54176 2.39015L2.23737 2.28869ZM5.7502 6.7502C5.75021 6.78045 5.75021 6.81091 5.7502 6.8416L5.7502 9.5002C5.7502 10.6722 5.75127 11.5546 5.80693 12.2502H16.022C16.5179 12.2502 16.8305 12.2492 17.0678 12.2236C17.287 12.1999 17.3713 12.161 17.424 12.1263C17.4766 12.0916 17.5455 12.0294 17.6537 11.8373C17.7707 11.6292 17.8948 11.3423 18.0901 10.8865L18.5187 9.88652C18.9332 8.91932 19.2087 8.27152 19.3379 7.78145C19.4636 7.30522 19.3999 7.16069 19.3515 7.08734C19.3032 7.01398 19.1954 6.89853 18.7082 6.8264C18.2068 6.75218 17.5029 6.7502 16.4506 6.7502H5.7502Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.2502 19.5002C5.2502 20.7428 6.25756 21.7502 7.5002 21.7502C8.74284 21.7502 9.7502 20.7428 9.7502 19.5002C9.7502 18.2576 8.74284 17.2502 7.5002 17.2502C6.25756 17.2502 5.2502 18.2576 5.2502 19.5002ZM7.5002 20.2502C7.08599 20.2502 6.7502 19.9144 6.7502 19.5002C6.7502 19.086 7.08599 18.7502 7.5002 18.7502C7.91442 18.7502 8.2502 19.086 8.2502 19.5002C8.2502 19.9144 7.91442 20.2502 7.5002 20.2502Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.5002 21.7503C15.2576 21.7503 14.2502 20.7429 14.2502 19.5003C14.2502 18.2576 15.2576 17.2503 16.5002 17.2503C17.7428 17.2503 18.7502 18.2576 18.7502 19.5003C18.7502 20.7429 17.7428 21.7503 16.5002 21.7503ZM15.7502 19.5003C15.7502 19.9145 16.086 20.2503 16.5002 20.2503C16.9144 20.2503 17.2502 19.9145 17.2502 19.5003C17.2502 19.0861 16.9144 18.7503 16.5002 18.7503C16.086 18.7503 15.7502 19.0861 15.7502 19.5003Z'
      fill='black'
    />
  </svg>
)
