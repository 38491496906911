import React from 'react'

export const EnvelopeIconThird = ({ ...props }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1099 4C18.8978 4 21 6.30141 21 9.35331V15.1708C21 16.7327 20.4541 18.1439 19.462 19.1451C18.5721 20.0421 17.4176 20.5173 16.1233 20.5173H7.87423C6.58242 20.5173 5.42874 20.043 4.53795 19.1451C3.54586 18.1439 3 16.7327 3 15.1708V9.35331C3 6.30141 5.10223 4 7.89014 4H16.1099ZM16.1099 5.27056H7.89014C5.78456 5.27056 4.25581 6.98752 4.25581 9.35331V15.1708C4.25581 16.3931 4.67107 17.4849 5.42456 18.2447C6.07423 18.9012 6.92233 19.2468 7.87674 19.2468H16.1099C16.1115 19.2451 16.1182 19.2468 16.1233 19.2468C17.0785 19.2468 17.9258 18.9012 18.5754 18.2447C19.3298 17.4849 19.7442 16.3931 19.7442 15.1708V9.35331C19.7442 6.98752 18.2154 5.27056 16.1099 5.27056ZM17.4293 9.19135C17.6478 9.46325 17.6068 9.86306 17.338 10.085L13.6175 13.1445C13.147 13.5223 12.5844 13.7112 12.0226 13.7112C11.4625 13.7112 10.9041 13.524 10.4369 13.1496L6.68205 10.0867C6.41163 9.86644 6.36977 9.46579 6.5866 9.19305C6.80512 8.92115 7.20028 8.87795 7.46986 9.09733L11.2214 12.1568C11.6927 12.5346 12.3567 12.5346 12.8313 12.1535L16.5452 9.09903C16.8148 8.87625 17.21 8.9186 17.4293 9.19135Z'
    />
  </svg>
)
