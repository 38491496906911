export const CartIconCopperStudio = () => (
  <svg
    width='29'
    height='27'
    viewBox='0 0 29 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.16959 0H2.72904L2.80702 0.0161194C4.96867 0.0594619 6.85744 1.53673 7.48538 3.67522L7.79727 4.78746H24.0624C25.4987 4.79422 26.8625 5.44035 27.8051 6.5606C28.7449 7.68795 29.1609 9.18378 28.9435 10.6549L28.0078 17.3284C27.6962 19.8863 25.6052 21.8137 23.1111 21.8418H13.9415C11.751 21.8334 9.82181 20.3493 9.18519 18.1827L5.23977 4.43284C4.93764 3.25319 3.90956 2.42812 2.72904 2.41791H1.16959C0.523644 2.41791 0 1.87664 0 1.20896C0 0.541268 0.523644 0 1.16959 0ZM11.4308 17.409C11.7618 18.5567 12.7829 19.3433 13.9415 19.3433H23.1111C24.4018 19.3288 25.4949 18.3555 25.6998 17.0382L26.6355 10.3487C26.7629 9.56998 26.5552 8.77261 26.0666 8.16541C25.5781 7.55821 24.8575 7.2017 24.0936 7.18925H8.49903L11.4308 17.409Z'
      fill='black'
    />
    <path
      d='M13.5828 23.7761C12.7216 23.7761 12.0234 24.4978 12.0234 25.3881C12.0234 26.2783 12.7216 27 13.5828 27C14.4441 27 15.1423 26.2783 15.1423 25.3881C15.1423 24.4978 14.4441 23.7761 13.5828 23.7761Z'
      fill='black'
    />
    <path
      d='M22.9396 23.7761C22.0783 23.7761 21.3801 24.4978 21.3801 25.3881C21.3801 26.2783 22.0783 27 22.9396 27C23.8008 27 24.499 26.2783 24.499 25.3881C24.499 24.4978 23.8008 23.7761 22.9396 23.7761Z'
      fill='black'
    />
  </svg>
)
