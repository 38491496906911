import {
  createRef,
  FC,
  memo,
  useEffect,
  KeyboardEvent,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react'
import cn from 'classnames'
import { Cross, LoupeIcon } from '@components/icons'
import { LoupeIconSecond } from '@components/iconsSetTwo'
import { Icon } from '@components/common/Icon'
import { LoupeIconThird } from '@components/iconsSetThree'
import { LoupeIconNinelife } from '@components/iconSetNinelife'
import { SearchNinefit } from '@components/iconSetNinefit/SearchNinefit'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'

interface ICopperstudioSearchbarProps {
  id?: string
  placeholder?: string
  searchBarVisible: boolean
  setSearchBarVisible: Dispatch<SetStateAction<boolean>>
}

const CopperstudioSearchbar: FC<ICopperstudioSearchbarProps> = ({
  id = 'store',
  placeholder = 'What are you looking for?',
  searchBarVisible,
  setSearchBarVisible,
}) => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const refSearch: RefObject<HTMLInputElement> = createRef()

  useEffect(() => {
    router.prefetch('/store')
  }, [router])

  useEffect(() => {
    if (refSearch.current) {
      refSearch.current.value = (searchParams?.get('q') as string) || ''
    }
  }, [searchParams])

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      onSearch()
    }
  }
  const onSearch = () => {
    const q = refSearch.current?.value

    const newSearch = new URLSearchParams(
      Array.from(searchParams?.entries() || []),
    )

    newSearch.set('q', q || '')
    newSearch.set('page', '1')
    router.push(
      `${pathname?.includes('store') ? pathname : '/store'}?${
        newSearch ? `${newSearch?.toString()}` : ''
      }`,
    )
  }

  const handleDisplaySearchBar = () => {
    setSearchBarVisible(true)
  }
  const handleHideSearchBar = () => {
    setSearchBarVisible(false)
  }

  return (
    <>
      <label className='hidden' htmlFor={id}>
        Search
      </label>
      <input
        placeholder={placeholder}
        className={cn(
          'md:transition-all border-b-2 border-brand md:duration-500 md:ease-in-out outline-none',
          {
            'w-0': !searchBarVisible,
            'w-60 md:w-80': searchBarVisible,
          },
        )}
        ref={refSearch}
        name={id}
        data-id={id}
        defaultValue={searchParams?.get('q') || ''}
        aria-label='Products search input'
        onKeyUp={handleKeyUp}
      />
      {searchBarVisible && (
        <button
          type='button'
          className='text-brand'
          onClick={handleHideSearchBar}
        >
          <Cross className='w-5 h-5' />
        </button>
      )}
      {!searchBarVisible && (
        <button type='button' onClick={handleDisplaySearchBar}>
          <Icon
            set1={<LoupeIcon />}
            set2={<LoupeIconSecond />}
            set3={<LoupeIconThird />}
            setNinelife={<LoupeIconNinelife />}
            setNinefit={<SearchNinefit />}
            setCopperStudio={
              <svg
                viewBox='0 0 32 32'
                fill='none'
                className='w-6 h-6 md:w-8 md:h-8'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4.46851 10.7507C6.44108 6.06878 11.0629 3.01372 16.1938 3.00018C21.7236 2.97139 26.6407 6.47257 28.3644 11.6663C30.0882 16.8601 28.225 22.56 23.7525 25.7747C19.28 28.9894 13.22 28.9846 8.75275 25.7628L4.76944 29.7001C4.36445 30.1 3.70836 30.1 3.30337 29.7001C2.89888 29.2998 2.89888 28.6513 3.30337 28.251L7.16219 24.4367C3.55772 20.8273 2.49594 15.4326 4.46851 10.7507ZM6.31791 19.6366C7.97413 23.5817 11.8726 26.1511 16.1938 26.1456V26.0909C22.0585 26.0835 26.8194 21.402 26.8574 15.6051C26.863 11.3338 24.2636 7.48031 20.2724 5.84321C16.2813 4.20611 11.6854 5.10822 8.62985 8.12846C5.57434 11.1487 4.6617 15.6916 6.31791 19.6366Z'
                  fill='black'
                />
              </svg>
            }
          />
        </button>
      )}
    </>
  )
}

export default memo(CopperstudioSearchbar)
