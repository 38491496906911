import { useUI } from '@components/ui'
import { MODAL_VIEWS } from '@constants/common'
import { CookiesAcceptModes, useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import { useEffect } from 'react'

export const CookiesManager = ({ pathname }: { pathname: string | null }) => {
  const { openModal, setModalView, closeModal } = useUI()
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()

  useEffect(() => {
    if (acceptedCookies === null && process.env.NEXT_PUBLIC_CONSENT_MODE_ENABLED && !pathname?.includes('policy/privacy-policy')) {
      setModalView(MODAL_VIEWS.cookiesModal, {
        maxWidth: 'medium',
        disableCloseOnOutsideClick: true,
        closeIconSlot: (
          <span className='flex items-center gap-2'>
            <svg
              width='11'
              height='10'
              viewBox='0 0 11 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.778686 0.322882C0.348212 0.753391 0.348213 1.45139 0.778686 1.88189L3.89616 4.99962L0.777933 8.11811C0.34746 8.54862 0.34746 9.24661 0.777933 9.67712C1.20841 10.1076 1.90634 10.1076 2.33682 9.67712L5.45504 6.55864L8.57309 9.67695C9.00357 10.1075 9.7015 10.1075 10.132 9.67695C10.5625 9.24644 10.5624 8.54845 10.132 8.11794L7.01392 4.99962L10.1312 1.88206C10.5617 1.45155 10.5617 0.753558 10.1312 0.323049C9.70075 -0.107461 9.00282 -0.10746 8.57234 0.323049L5.45504 3.44061L2.33757 0.322882C1.90709 -0.107627 1.20916 -0.107627 0.778686 0.322882Z'
                fill='#C4C4C4'
              />
            </svg>
            <span className='text-sm text-brand'>Reject All</span>
          </span>
        ),
        onClose: (cookiesMode: CookiesAcceptModes) => {
          onAcceptCookies(cookiesMode || CookiesAcceptModes.REJECTED_ALL)
          closeModal()
        },
      })
      openModal()
    } else {
      closeModal()
    }
  }, [acceptedCookies, pathname])

  return null
}
