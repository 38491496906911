'use client'

import { FC } from 'react'
import { useTaxonsContext } from '@contexts/taxonsContext'
import { useBrandThemeContext } from '@contexts/index'
import { MENU_TYPE } from '@constants/common'
import MenuItem from './MenuItem'
import s from './TopMenu.module.css'

export const TopMenu: FC = () => {
  const taxons = useTaxonsContext()
  const { menu_type: menuType } = useBrandThemeContext()

  return (
    menuType &&
    menuType !== MENU_TYPE.SIDE_MENU_ONLY && (
      <nav className={s.root}>
        <ul className={s.linksWrapper}>
          {taxons.map(taxon => (
            <MenuItem key={taxon.id} item={taxon} />
          ))}
        </ul>
      </nav>
    )
  )
}
