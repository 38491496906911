import { CmsPages } from 'models/cmsPage'

type GetCmsPagesProps = {
  perPage: number
  page: 'homepage' | 'help' | 'faq' | 'about us' | 'checkout policy'
}
export const getCmsPages = async ({
  perPage,
  page,
}: GetCmsPagesProps): Promise<CmsPages> => {
  const apiUrl = process.env.NEXT_PUBLIC_SPREE_API_HOST

  
  const pages = await fetch(
    `${apiUrl}/api/v2/storefront/cms_pages?${new URLSearchParams({
      'filter[title]': page,
      include: 'cms_sections',
      per_page: String(perPage) || '1',
    })}`,
    /* @ts-expect-error next revalidate */
    { next: { revalidate: Number(process.env.NEXT_PUBLIC_REVALIDATE_CMS) } },
  )

  const pagesJson = await pages.json()
  
  return pagesJson;
}
