import { getUserTokenResponse } from '@framework/utils/tokens/user-token-response'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'

export const CONSENT_COOKIE_NAME = 'consentGrantedNew'

export enum CookiesAcceptModes {
  ALL_ACCEPTED = 'allAccepted',
  ESSENTIALS_ACCEPTED = 'essentialsAccepted',
  REJECTED_ALL = 'rejectedAll',
}

const USER_CONSENT_PERMISSIONS_SET = {
  [CookiesAcceptModes.ALL_ACCEPTED]: {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted',
  },
  [CookiesAcceptModes.ESSENTIALS_ACCEPTED]: {
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    ad_storage: 'denied',
    analytics_storage: 'granted',
  },
  [CookiesAcceptModes.REJECTED_ALL]: {
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    ad_storage: 'denied',
    analytics_storage: 'denied',
  },
}

function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer?.push(arguments)
}

export const useAcceptCookies = () => {
  const [acceptedCookies, setAcceptedCookies] =
    useState<CookiesAcceptModes | null>(CookiesAcceptModes.REJECTED_ALL)
  const [isInitialized, setIsInitialized] = useState(false)

  const saveUserConsent = useCallback(
    async (acceptMode: CookiesAcceptModes | null) => {
      const token = getUserTokenResponse()
      
      if (!token || !acceptMode) {
        return
      }
      await axios.patch(
        `${process.env.NEXT_PUBLIC_SPREE_API_HOST}/api/v2/storefront/account`,
        {
          user: {
            consent_signals: USER_CONSENT_PERMISSIONS_SET[acceptMode],
          },
        },
        {
          headers: {
            Authorization: `${token?.token_type} ${token?.access_token}`,
            Token: token?.access_token || '',
          },
        },
      )
    },
    [],
  )

  useEffect(() => {
    const cookieValue = Cookies.get(CONSENT_COOKIE_NAME)

    setAcceptedCookies(cookieValue as CookiesAcceptModes)
  }, [])

  useEffect(() => {
    if (!acceptedCookies) {
      setAcceptedCookies(null)
      setIsInitialized(true)
    } else if (acceptedCookies === CookiesAcceptModes.ALL_ACCEPTED) {
      setIsInitialized(true)

      gtag(
        // @ts-expect-error
        'consent',
        'update',
        USER_CONSENT_PERMISSIONS_SET[CookiesAcceptModes.ALL_ACCEPTED],
      )
    } else if (acceptedCookies === CookiesAcceptModes.ESSENTIALS_ACCEPTED) {
      setIsInitialized(true)

      gtag(
        // @ts-expect-error
        'consent',
        'update',
        USER_CONSENT_PERMISSIONS_SET[CookiesAcceptModes.ESSENTIALS_ACCEPTED],
      )
    }
  }, [acceptedCookies])

  const acceptCookies = (acceptMode: CookiesAcceptModes) => {
    if (acceptMode === CookiesAcceptModes.ALL_ACCEPTED) {
      setIsInitialized(true)
      setAcceptedCookies(CookiesAcceptModes.ALL_ACCEPTED)
      Cookies.set(CONSENT_COOKIE_NAME, CookiesAcceptModes.ALL_ACCEPTED, {
        expires: 365,
      })
    } else if (acceptMode === CookiesAcceptModes.ESSENTIALS_ACCEPTED) {
      setIsInitialized(true)
      setAcceptedCookies(CookiesAcceptModes.ESSENTIALS_ACCEPTED)
      Cookies.set(CONSENT_COOKIE_NAME, CookiesAcceptModes.ESSENTIALS_ACCEPTED, {
        expires: 365,
      })
    } else {
      setAcceptedCookies(CookiesAcceptModes.REJECTED_ALL)
      Cookies.set(CONSENT_COOKIE_NAME, CookiesAcceptModes.REJECTED_ALL, {
        expires: 365,
      })
    }
    saveUserConsent(acceptMode)
  }

  return {
    acceptedCookies,
    onAcceptCookies: acceptCookies,
    isInitialized,
    saveUserConsent,
  }
}

