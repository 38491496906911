import dynamic from 'next/dynamic'
import { MODAL_VIEWS } from '@constants/common'
import { Loading } from '@components/common'
import { FC } from 'react'
import { useUI } from '@components/ui'

const dynamicProps = {
  loading: Loading,
}

const Modal = dynamic(() => import('@components/ui/Modal'), {
  ...dynamicProps,
  ssr: false,
})

const SignUpView = dynamic(() => import('@components/auth/SignUpView'), {
  ...dynamicProps,
})

const ShareWishlist = dynamic(
  () => import('@components/wishlist/ShareWishlist/ShareWishlist'),
  {
    ...dynamicProps,
  },
)

const LoginView = dynamic(() => import('@components/auth/LoginView'), {
  ...dynamicProps,
})

const ConfirmationView = dynamic(
  () => import('@components/common/checkout/ConfirmationView'),
  {
    ...dynamicProps,
  },
)

const RefreshPageView = dynamic(
  () => import('@components/common/checkout/RefreshPageView'),
  {
    ...dynamicProps,
  },
)

const RequestView = dynamic(
  () => import('@components/faq/CustomerSupportBlock/components/RequestView'),
  {
    ...dynamicProps,
  },
)

const MessageView = dynamic(
  () => import('@components/faq/CustomerSupportBlock/components/MessageView'),
  {
    ...dynamicProps,
  },
)

const PolicyView = dynamic(
  () => import('@components/common/checkout/PolicyView'),
  {
    ...dynamicProps,
  },
)

const RemoveWishlistConfirmation = dynamic(
  () => import('@components/wishlist/RemoveWishlistConfirmation'),
  {
    ...dynamicProps,
  },
)

const BulkView = dynamic(() => import('@components/bulkOrder/BulkView'), {
  ...dynamicProps,
})

const AddAddress = dynamic(
  () => import('@components/account/AccountBlock/AddAddress'),
  {
    ...dynamicProps,
  },
)

const CookiesModal = dynamic(
  () => import('@components/cookiesModal/CookiesModal'),
  {
    ...dynamicProps,
  },
)

const renderModal = (modalView: string) => {
  switch (modalView) {
    case MODAL_VIEWS.login:
      return <LoginView />
    case MODAL_VIEWS.signUp:
      return <SignUpView />
    case MODAL_VIEWS.request:
      return <RequestView />
    case MODAL_VIEWS.message:
      return <MessageView />
    case MODAL_VIEWS.confirm:
      return <ConfirmationView />
    case MODAL_VIEWS.refresh:
      return <RefreshPageView />
    case MODAL_VIEWS.shareWishlist:
      return <ShareWishlist />
    case MODAL_VIEWS.policyView:
      return <PolicyView />
    case MODAL_VIEWS.confirmRemoveWishlist:
      return <RemoveWishlistConfirmation />
    case MODAL_VIEWS.bulkView:
      return <BulkView />
    case MODAL_VIEWS.addAddress:
      return <AddAddress />
    case MODAL_VIEWS.editAddress:
      return <AddAddress editAddress />
    case MODAL_VIEWS.cookiesModal:
      return <CookiesModal />
    default:
      return <div />
  }
}

export const ModalUI: FC = () => {
  const { displayModal, closeModal, modalView } = useUI()

  return displayModal ? (
    <Modal onClose={closeModal}>{renderModal(modalView)}</Modal>
  ) : null
}
