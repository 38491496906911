const StarAux = ({ ...props }) => (
  <svg
    width='18'
    height='17'
    viewBox='0 0 18 17'
    fill='currentColor'
    {...props}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.0207 6.21885L9.00006 0L6.97943 6.21885H0.440552L5.73062 10.0623L3.70999 16.2812L9.00006 12.4377L9.10796 12.5161L12.3035 10.1671L12.2695 10.0623L17.5596 6.21885H11.0207ZM12.6291 11.1689L9.95709 13.133L14.2901 16.2812L12.6291 11.1689Z'
    />
  </svg>
)

export default StarAux
