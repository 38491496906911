import { FC, memo } from 'react'
import cn from 'classnames'
import rangeMap from '@lib/range-map'
import { StarAux } from '@components/icons'
import s from './RatingAux.module.css'

export interface RatingProps {
  value: number
}

const Quantity: FC<RatingProps> = ({ value }) => {
  const percent = (value / 5) * 100

  return (
    <div
      className='flex flex-row mr-2.5'
      style={{
        backgroundImage: `linear-gradient(90deg, var(--brand) ${20}%, #dcdce6 ${percent}%)`,
      }}
    >
      {rangeMap(5, i => (
        <span
          key={`star_${i}`}
          className={cn(
            s.starItem,
            'flex flex-col justify-center items-center border-r-4 text-white border-topPanelBg',
          )}
        >
          <StarAux />
        </span>
      ))}
    </div>
  )
}

export default memo(Quantity)
