'use client'

import { FC, useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import { NormalizedMenuTaxon } from '@models'
import { useSearchParams } from 'next/navigation'
import { DESKTOP_WIDTH_XL } from '@constants/common'
import { useCurrentWidth } from '@lib/hooks/useCurrentWidth'
import s from './MenuItem.module.css'

interface MenuItemProps {
  item: NormalizedMenuTaxon
}

const MenuItem: FC<MenuItemProps> = ({
  item: { name, href, dropDownItems },
}) => {
  const searchParam = useSearchParams()
  const category = useMemo(() => searchParam?.get('category'), [searchParam])
  const currentWidth = useCurrentWidth()
  const [selectedItem, setSelectedItem] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setSelectedItem(false)
    if (category === href.slice(7)) setSelectedItem(true)
  }, [href, category])

  const handleHoverOver = () => {
    if (currentWidth >= DESKTOP_WIDTH_XL) setIsOpen(true)
  }

  const handleHoverLeave = () => {
    setIsOpen(false)
  }

  return (
    <li
      className={s.taxonItem}
      onMouseOver={handleHoverOver}
      onMouseLeave={handleHoverLeave}
      onFocus={handleHoverOver}
    >
      <Link
        className={`${s.linkItem} ${selectedItem ? s.activeLink : ''}`}
        href={href}
      >
        {name}
      </Link>
      <div className={`${isOpen ? s.dropdownMenu : 'hidden'}`}>
        <ul className='z-10 bg-grayBg'>
          {dropDownItems?.map(item => (
            <li key={item.id}>
              <Link href={item.href}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  )
}

export default MenuItem
