import { FC, useRef, MutableRefObject } from 'react'
import { UserIcon } from '@components/icons'
import { UserIconSecond } from '@components/iconsSetTwo'
import { UserThird } from '@components/iconsSetThree'
import { Icon } from '@components/common'
import { UserIconNinelife } from '@components/iconSetNinelife'
import { AccountNinefit } from '@components/iconSetNinefit/AccountNinefit'
import { UserIconCopperStudio } from '@components/iconSetCopperStudio/UserIconCopperStudio'

interface Props {
  className?: string
}

const Avatar: FC<Props> = ({ className }) => {
  const ref = useRef() as MutableRefObject<HTMLInputElement>

  return (
    <div
      ref={ref}
      className='flex flex-col items-center justify-center rounded-full'
    >
      <Icon
        set1={<UserIcon className={className} />}
        set2={<UserIconSecond className={className} />}
        set3={<UserThird className={className} />}
        setNinelife={<UserIconNinelife className={className} />}
        setNinefit={<AccountNinefit className={className} />}
        setCopperStudio={<UserIconCopperStudio className={className} />}
      />
    </div>
  )
}

export default Avatar
