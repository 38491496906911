import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import Link from 'next/link'
import cn from 'classnames'
import useCart from '@framework/cart/use-cart'
import useCustomer from '@framework/customer/use-customer'
import { Avatar, Icon } from '@components/common'
import { Heart, BagIcon } from '@components/icons'
import { HeartSecond, BagIconSecond } from '@components/iconsSetTwo'
import { useUI } from '@components/ui/context'
import useWishlist from '@framework/wishlist/use-wishlist'
import { BagIconThird, HeartThird } from '@components/iconsSetThree'
import { CartNinelife, HeartIconNinelife } from '@components/iconSetNinelife'
import { HeartNinefit } from '@components/iconSetNinefit/HeartNinefit'
import { CartNinefit } from '@components/iconSetNinefit/CartNinefit'
import { CartIconCopperStudio } from '@components/iconSetCopperStudio/CartIconCopperStudio'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { LineItem } from '@commerce/types/cart'

import { WishlistIconCopperStudio } from '@components/iconSetCopperStudio/WishlistIconCopperStudio'
import { MODAL_VIEWS } from '@constants/common'
import s from './UserNav.module.css'
import CopperStudioSearchBar from '../CopperStudioSearchBar'

interface Props {
  className?: string
  searchBarVisible: boolean
  setSearchBarVisible: Dispatch<SetStateAction<boolean>>
}

const countItem = (count: number, item: LineItem) => count + item.quantity

const UserNav: FC<Props> = ({
  className,
  searchBarVisible,
  setSearchBarVisible,
}) => {
  const { data: wishlist, isEmpty } = useWishlist()

  const { data } = useCart()
  const {
    data: {
      attributes: { is_home_brand: isHomeBrand },
    },
  } = useStoreDataContext()
  const { data: customer } = useCustomer()
  const { openModal, setModalView } = useUI()
  const itemsCount = data?.lineItems.reduce(countItem, 0) ?? 0

  const addItemsToWishlist = () => {
    if (!wishlist) {
      return
    }

    return Promise.resolve()
  }

  useEffect(() => {
    addItemsToWishlist()
  }, [wishlist])

  return (
    <nav className={cn(s.root, className)}>
      <ul className={s.list}>
        {isHomeBrand && (
          <li
            className={cn('md:!flex', s.item, {
              '!hidden': searchBarVisible,
            })}
          >
            <CopperStudioSearchBar
              searchBarVisible={searchBarVisible}
              setSearchBarVisible={setSearchBarVisible}
            />
          </li>
        )}
        {process.env.COMMERCE_WISHLIST_ENABLED && (
          <li
            className={cn(s.item, {
              '!hidden': searchBarVisible || isHomeBrand,
              'md:!block': isHomeBrand,
            })}
          >
            {customer ? (
              <Link
                aria-label='Wishlist'
                className={s.userNavItem}
                href='/wishlist'
              >
                <Icon
                  set1={<Heart />}
                  set2={<HeartSecond />}
                  set3={<HeartThird />}
                  setNinelife={<HeartIconNinelife />}
                  setNinefit={<HeartNinefit />}
                  setCopperStudio={<WishlistIconCopperStudio />}
                />
                {!isEmpty && (
                  <span className={s.badge}>{wishlist?.items.length}</span>
                )}
              </Link>
            ) : (
              <button
                type='button'
                className={s.userNavItem}
                aria-label='Menu'
                onClick={() => {
                  setModalView(MODAL_VIEWS.login, { maxWidth: 'small' })
                  openModal()
                }}
              >
                <Icon
                  set1={<Heart />}
                  set2={<HeartSecond />}
                  set3={<HeartThird />}
                  setNinelife={<HeartIconNinelife />}
                  setNinefit={<HeartNinefit />}
                  setCopperStudio={<WishlistIconCopperStudio />}
                />
              </button>
            )}
          </li>
        )}
        {process.env.COMMERCE_CUSTOMERAUTH_ENABLED && (
          <li
            className={cn(s.item, {
              'md:!block': isHomeBrand,
            })}
          >
            {customer ? (
              <Link
                data-testid='account_button'
                aria-label='Profile'
                className={s.userNavItem}
                href='/account'
              >
                <Avatar />
              </Link>
            ) : (
              <button
                // eslint-disable-next-line react/no-unknown-property
                data-testid='account_button'
                type='button'
                className={cn(s.userNavItem, {
                  [s.iconCopperStudio]: isHomeBrand,
                })}
                aria-label='Login'
                onClick={() => {
                  setModalView(MODAL_VIEWS.login, { maxWidth: 'small' })
                  openModal()
                }}
                data-cy='login'
              >
                <Avatar />
              </button>
            )}
          </li>
        )}
        {process.env.COMMERCE_CART_ENABLED && (
          <li
            className={cn(s.item, {
              'md:!block': isHomeBrand,
            })}
          >
            <Link aria-label='Cart' className={cn(s.userNavItem)} href='/cart'>
              <Icon
                set1={<BagIcon />}
                set2={<BagIconSecond />}
                set3={<BagIconThird />}
                setNinelife={<CartNinelife />}
                setNinefit={<CartNinefit />}
                setCopperStudio={<CartIconCopperStudio />}
              />
              {itemsCount > 0 && <span className={s.badge}>{itemsCount}</span>}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default UserNav
