import { useEffect, useState } from 'react'
import { DoubleUpChevron } from '@components/icons'
import s from './ScrollToTop.module.css'

export const ScrollToTop = () => {
  const [display, setDisplay] = useState('none')

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleScroll = () => {
    const position = window.scrollY

    setDisplay(position >= 150 ? 'flex' : 'none')
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <button
      type='button'
      aria-label='scroll page to top'
      id='scrollToTop'
      className={s.scrollToTopButton}
      style={{ display }}
      onClick={scrollToTop}
    >
      <DoubleUpChevron
        className='text-messageUsScroll'
        width={26}
        height={26}
      />
    </button>
  )
}
