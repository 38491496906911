import axios from 'axios'


type PostSubscriptionProps = {
  email: string,
}

export const postSubscription = ({ email }: PostSubscriptionProps) => {
  const apiUrl = process.env.NEXT_PUBLIC_SPREE_API_HOST
  return axios.post(`${apiUrl}/api/custom/subscription`, {
    email,
  })
}