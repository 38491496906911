const DoubleUpChevron = ({ ...props }) => (
  <svg
    width='31'
    height='30'
    viewBox='0 0 31 30'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1805 0.620867L15.5118 0L14.8325 0.609091L0.332464 13.6091C-0.0787501 13.9778 -0.113235 14.61 0.25544 15.0212C0.624115 15.4324 1.25634 15.4669 1.66755 15.0982L15.4882 2.70732L28.8196 15.0865C29.2243 15.4623 29.857 15.4388 30.2328 15.0341C30.6086 14.6294 30.5852 13.9967 30.1805 13.6209L16.1805 0.620867ZM16.1805 13.6209L15.5118 13L14.8325 13.6091L0.332464 26.6091C-0.0787501 26.9778 -0.113235 27.61 0.25544 28.0212C0.624115 28.4324 1.25634 28.4669 1.66755 28.0982L15.4882 15.7073L28.8196 28.0865C29.2243 28.4623 29.857 28.4388 30.2328 28.0341C30.6086 27.6294 30.5852 26.9967 30.1805 26.6209L16.1805 13.6209Z'
    />
  </svg>
)

export default DoubleUpChevron
