export const BusSecond = ({ ...props }) => (
  <svg
    role='img'
    width='28'
    height='22'
    viewBox='0 0 28 22'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Delivery Truck Icon</title>
    <path
      d='M8.33366 20.3333C9.80642 20.3333 11.0003 19.1394 11.0003 17.6667C11.0003 16.1939 9.80642 15 8.33366 15C6.8609
        15 5.66699 16.1939 5.66699 17.6667C5.66699 19.1394 6.8609 20.3333 8.33366 20.3333Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M21.6667 20.3333C23.1394 20.3333 24.3333 19.1394 24.3333 17.6667C24.3333 16.1939 23.1394 15 21.6667 15C20.1939 15
        19 16.1939 19 17.6667C19 19.1394 20.1939 20.3333 21.6667 20.3333Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M5.66699 17.6667H3.00033V12.3333M1.66699 1.66666H16.3337V17.6667M11.0003 17.6667H19.0003M24.3337
        17.6667H27.0003V9.66666H16.3337M16.3337 2.99999H23.0003L27.0003 9.66666M3.00033 6.99999H8.33366'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </svg>
)
