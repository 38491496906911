import React from 'react'

export const MapIconNinelife = ({ ...props }) => (
  <svg
    role='img'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Map Icon</title>
    <g clipPath='url(#clip0_6646_3230)'>
      <path
        d='M17.0833 6.80005L16.95 6.82505L12.5 8.55005L7.5 6.80005L2.8 8.38338C2.625 8.44172 2.5 8.59172 2.5 8.78338V21.3834C2.5 21.6167 2.68333 21.8 2.91667 21.8L3.05 21.775L7.5 20.05L12.5 21.8L17.2 20.2167C17.375 20.1584 17.5 20.0084 17.5 19.8167V7.21672C17.5 6.98338 17.3167 6.80005 17.0833 6.80005ZM12.5 20.1334L7.5 18.375V8.46672L12.5 10.225V20.1334Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_6646_3230'>
        <rect
          width='20'
          height='20'
          fill='currentColor'
          transform='translate(0 4.30005)'
        />
      </clipPath>
    </defs>
  </svg>
)
