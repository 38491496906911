'use client'

import '@assets/main.css'
import '@assets/chrome-bug.css'
import 'keen-slider/keen-slider.min.css'
import { Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { CommerceProvider } from '@framework'
import {
  Navbar,
  TopPanel,
  TopMenu,
  Footer,
  ModalUI,
  SidebarUI,
} from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  BrandThemeProvider,
  setThemeVariables,
} from '@contexts/brandThemeContext'
import { StoreDataProvider } from '@contexts/storeDataContext/storeDataContextProvider'
import { TaxonsProvider } from '@contexts/taxonsContext'
import { usePathname } from 'next/navigation'
import { AllCmsPages } from '@models/cmsPage'
import { getAllCmsPages } from '@api/getAllCmsPages'
import { ScrollToTop } from '@components/scrollToTop'
import { FooterBanner } from '@components/common/FooterBanner/FooterBanner'
import { GAScripts } from 'appComponents/GAScripts/GAScripts'
import { CookiesManager } from 'appComponents/CookiesManager/CookiesManager'

const checkoutPages = [
  '/shipping-details',
  '/payment-details',
  '/shipping-method',
  '/order-confirmation',
]

const RootLayout = ({ children }: { children: React.ReactNode }) => {
  const [allPages, setAllPages] = useState<AllCmsPages>()
  const [allPagesFetched, setAllPagesFetched] = useState(false)
  const [isThemeConfigured, setIsThemeConfigured] = useState(false)
  const pathname = usePathname()

  const isProductPage = ['/products/[slug]'].includes(pathname || '')

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  useLayoutEffect(() => {
    setThemeVariables()
    setIsThemeConfigured(true)
  }, [])

  useEffect(() => {
    setAllPagesFetched(false)
    getAllCmsPages().then(res => {
      setAllPages(res)
      setAllPagesFetched(true)
    })
  }, [])

  return (
    <>
      <Suspense>
        <ManagedUIContext>
          <StoreDataProvider>
            <BrandThemeProvider>
              <GAScripts />
              {/* <DefaultSEO /> */}
              <CommerceProvider locale='en-US'>
                {/* {isThemeConfigured && ( */}
                <div
                  className='absolute w-full h-full bg-white'
                  style={{
                    zIndex: isThemeConfigured ? 1 : 100,
                    display: isThemeConfigured ? 'none' : 'block',
                  }}
                />
                <TaxonsProvider>
                  <div className='bg-white mx-auto transition-colors duration-150 max-w-full'>
                    {!checkoutPages.includes(pathname || '') && (
                      <>
                        <TopPanel />
                        <Navbar />

                        <TopMenu />
                      </>
                    )}

                    <main className='fit'>{children}</main>

                    <FooterBanner />
                    {!checkoutPages.includes(pathname || '') && (
                      <Footer
                        className={isProductPage ? 'lg:pb-28 pb-36' : ''}
                        allPagesFetched={allPagesFetched}
                        allPages={allPages}
                      />
                    )}
                    <ModalUI />
                    <SidebarUI />
                    <ScrollToTop />
                  </div>
                </TaxonsProvider>
                <ToastContainer
                  position='top-right'
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  draggable={false}
                  closeOnClick
                  pauseOnHover
                />
                {/* )} */}
                <CookiesManager pathname={pathname} />
              </CommerceProvider>
            </BrandThemeProvider>
          </StoreDataProvider>
        </ManagedUIContext>
      </Suspense>
    </>
  )
}

export default RootLayout
