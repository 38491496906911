export const BagIcon = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='31'
    viewBox='0 0 32 31'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Bag Icon</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.17386 3.2057C2.17386 2.91114 2.41264 2.67236 2.70719 2.67236H6.17574C6.88551 2.67236 7.51045 3.13996 7.71072
      3.8209L8.3141 5.87236H27.7984C28.9303 5.87236 29.7043 7.0156 29.2839 8.06659L25.8706 16.5999C25.6276 17.2074 25.0393
      17.6057 24.385 17.6057H11.7651L12.5937 20.4229C12.6604 20.6498 12.8687 20.8057 13.1053 20.8057H25.6405C25.9351
      20.8057 26.1739 21.0445 26.1739 21.339C26.1739 21.6336 25.9351 21.8724 25.6405 21.8724H13.1053C12.3955 21.8724
      11.7706 21.4048 11.5703 20.7238L6.6874 4.12187C6.62064 3.8949 6.41233 3.73903 6.17574 3.73903H2.70719C2.41264
      3.73903 2.17386 3.50025 2.17386 3.2057ZM11.4514 16.539L8.62782 6.93903H27.7984C28.1757 6.93903 28.4337 7.32011
      28.2935 7.67044L24.8802 16.2038C24.7992 16.4063 24.6031 16.539 24.385 16.539H11.4514ZM16.0405 25.6057C16.0405
      26.4894 15.3242 27.2057 14.4405 27.2057C13.5569 27.2057 12.8405 26.4894 12.8405 25.6057C12.8405 24.722 13.5569
      24.0057 14.4405 24.0057C15.3242 24.0057 16.0405 24.722 16.0405 25.6057ZM17.1072 25.6057C17.1072 27.0785 15.9133
      28.2724 14.4405 28.2724C12.9678 28.2724 11.7739 27.0785 11.7739 25.6057C11.7739 24.1329 12.9678 22.939 14.4405
      22.939C15.9133 22.939 17.1072 24.1329 17.1072 25.6057ZM22.9739 27.2057C23.8575 27.2057 24.5739 26.4894 24.5739
      25.6057C24.5739 24.722 23.8575 24.0057 22.9739 24.0057C22.0902 24.0057 21.3739 24.722 21.3739 25.6057C21.3739
      26.4894 22.0902 27.2057 22.9739 27.2057ZM22.9739 28.2724C24.4466 28.2724 25.6405 27.0785 25.6405 25.6057C25.6405
      24.1329 24.4466 22.939 22.9739 22.939C21.5011 22.939 20.3072 24.1329 20.3072 25.6057C20.3072 27.0785 21.5011
      28.2724 22.9739 28.2724ZM14.9739 9.6057C14.9739 9.31115 14.7351 9.07236 14.4405 9.07236C14.146 9.07236 13.9072
      9.31114 13.9072 9.6057V13.8724C13.9072 14.1669 14.146 14.4057 14.4405 14.4057C14.7351 14.4057 14.9739 14.1669
      14.9739 13.8724V9.6057ZM18.7072 9.07236C19.0017 9.07236 19.2405 9.31115 19.2405 9.6057V13.8724C19.2405 14.1669
      19.0017 14.4057 18.7072 14.4057C18.4126 14.4057 18.1739 14.1669 18.1739 13.8724V9.6057C18.1739 9.31114 18.4126
      9.07236 18.7072 9.07236ZM23.5072 9.6057C23.5072 9.31115 23.2684 9.07236 22.9739 9.07236C22.6793 9.07236 22.4405
       9.31114 22.4405 9.6057V13.8724C22.4405 14.1669 22.6793 14.4057 22.9739 14.4057C23.2684 14.4057 23.5072 14.1669
       23.5072 13.8724V9.6057Z'
    />
  </svg>
)
