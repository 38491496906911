export const EnvelopeIconSecond = ({ ...props }) => (
  <svg
    width='28'
    height='22'
    viewBox='0 0 28 22'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M24.667 0.333344H3.33366C1.86699 0.333344 0.680326 1.53334 0.680326 3.00001L0.666992 19C0.666992 20.4667 1.86699
      21.6667 3.33366 21.6667H24.667C26.1337 21.6667 27.3337 20.4667 27.3337 19V3.00001C27.3337 1.53334 26.1337 0.333344
      24.667 0.333344ZM23.3337 19H4.66699C3.93366 19 3.33366 18.4 3.33366 17.6667V5.66668L12.587 11.4533C13.4537 12 14.547
      12 15.4137 11.4533L24.667 5.66668V17.6667C24.667 18.4 24.067 19 23.3337 19ZM14.0003 9.66668L3.33366 3.00001H24.667L14.0003
      9.66668Z'
    />
  </svg>
)
