import { useHook, useMutationHook } from '@commerce/utils/use-hook'
import { mutationFetcher } from '@commerce/utils/default-fetcher'
import type { MutationHook, HookFetcherFn } from '@commerce/utils/types'
import type { LoginHook } from '@commerce/types/login'
import type { Provider } from '@commerce'

export type UseLogin<
  H extends MutationHook<LoginHook<any>> = MutationHook<LoginHook>,
> = ReturnType<H['useHook']>

export const fetcher: HookFetcherFn<LoginHook> = mutationFetcher

const fn = (provider: Provider) => provider.auth.useLogin!

const useLogin: UseLogin = (...args) => {
  const hook = useHook(fn)

  return useMutationHook({ fetcher, ...hook })(...args)
}

export default useLogin
