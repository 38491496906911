'use client'

import { FC } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import cn from 'classnames'
import { Email, RatingAux } from '@components/ui'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { Bus } from '@components/icons'
import { BusSecond } from '@components/iconsSetTwo'
import { TruckIconThird } from '@components/iconsSetThree'
import { Icon } from 'components/common'
import { BusIconNinelife } from '@components/iconSetNinelife'
import { DeliveryNinefit } from '@components/iconSetNinefit/DeliveryNinefit'
import { DeliveryMovingIconCopperStudio } from '@components/iconSetCopperStudio/DeliveryMovingIconCopperStudio'
import s from './TopPanel.module.css'

const Phone = dynamic(() => import('@components/ui/Phone'), { ssr: false })

const TopPanel: FC = () => {
  const {
    data: {
      attributes: {
        contact_phone: contactPhone,
        customer_support_email: customerSupportEmail,
        show_store_rate: showStoreRate,
        trustpilot_number_of_reviews: trustpilotNumberOfReviews,
        trustpilot_link: trustpilotLink,
        trustpilot_rating: trustpilotRating,
        show_tracking_page: showTrakingPage,
        is_home_brand: isHomeBrand,
      },
    },
  } = useStoreDataContext()

  return (
    <div className={s.root}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div
        data-testid='top_panel_link_wrapper'
        className={cn(s.linksWrapper, {
          '!justify-between': isHomeBrand,
          'w-full': isHomeBrand,
        })}
      >
        <div className='flex items-center'>
          <Phone
            value={contactPhone}
            linkStyles={s.linkItem}
            iconStyles={`${s.linkItemIconPhone} text-headerIcon`}
          />
          <Email
            value={customerSupportEmail}
            linkStyles={s.linkItem}
            iconStyles={`${s.linkItemIconPhone} text-headerIcon`}
          />
        </div>
        {showTrakingPage && (
          <Link
            data-testid='top_panel_track_your_order'
            className={cn(s.greyLinkItem, { '!text-white': isHomeBrand })}
            href='/track'
            title='Track Your Order'
          >
            <Icon
              set1={
                <Bus
                  alt='Track Your Order Truck Icon'
                  className='w-5 h-5 mr-2.5 text-textGray'
                />
              }
              set2={
                <BusSecond
                  alt='Track Your Order Truck Icon'
                  className='w-5 h-5 mr-2.5 text-textGray'
                />
              }
              set3={
                <TruckIconThird
                  alt='Track Your Order Truck Icon'
                  className='w-5 h-5 mr-2.5 text-textGray'
                />
              }
              setNinelife={
                <BusIconNinelife
                  alt='Track Your Order Truck Icon'
                  className='w-5 h-5 mr-2.5 text-textGray'
                />
              }
              setNinefit={
                <DeliveryNinefit
                  alt='Track Your Order Truck Icon'
                  className='w-5 h-5 mr-2.5 text-textGray'
                />
              }
              setCopperStudio={
                <DeliveryMovingIconCopperStudio
                  alt='Track Your Order Truck Icon'
                  className='w-5 h-5 mr-2.5 text-headerIcon'
                />
              }
            />
            Track Your Order
          </Link>
        )}
        {!isHomeBrand ? (
          <>
            <Link
              data-testid='top_panel_about_us'
              className={s.greyLinkItem}
              title='About Us'
              href='/about-us'
            >
              About Us
            </Link>
            <Link
              data-testid='top_panel_contact_us'
              className={s.greyLinkItem}
              title='Contact Us'
              href='/contact-us'
            >
              Contact Us
            </Link>
          </>
        ) : null}
      </div>
      {showStoreRate && (
        <Link className='flex items-center font-light' href={trustpilotLink}>
          <RatingAux value={+trustpilotRating} />
          <span className='flex-shrink-0'>
            {trustpilotRating} out of {trustpilotNumberOfReviews} reviews
          </span>
        </Link>
      )}
    </div>
  )
}

export default TopPanel
