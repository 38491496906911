import { createRef, FC, memo, useEffect, KeyboardEvent, RefObject } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { LoupeIcon } from '@components/icons'
import { LoupeIconSecond } from '@components/iconsSetTwo'
import { Button } from '@components/ui'
import { Icon } from '@components/common/Icon'
import { LoupeIconThird } from '@components/iconsSetThree'
import { LoupeIconNinelife } from '@components/iconSetNinelife'
import { SearchNinefit } from '@components/iconSetNinefit/SearchNinefit'
import s from './Searchbar.module.css'

interface Props {
  id?: string
  placeholder?: string
}

const Searchbar: FC<Props> = ({
  id = 'store',
  placeholder = 'What are you looking for?',
}) => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const refSearch: RefObject<HTMLInputElement> = createRef()

  useEffect(() => {
    router.prefetch('/store')
  }, [router])

  useEffect(() => {
    if (refSearch.current) {
      refSearch.current.value = (searchParams?.get('q') as string) || ''
    }
  }, [searchParams])

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      onSearch()
    }
  }
  const onSearch = () => {
    const q = refSearch.current?.value
    const newSearch = new URLSearchParams(
      Array.from(searchParams?.entries() || []),
    )

    newSearch.set('q', q || '')
    newSearch.set('page', '1')
    router.push(
      `${pathname?.includes('store') ? pathname : '/store'}?${
        newSearch ? `${newSearch?.toString()}` : ''
      }`,
    )
  }

  return (
    <div className={s.searchBox}>
      <label className='hidden' htmlFor={id}>
        Search
      </label>
      <input
        ref={refSearch}
        name={id}
        data-id={id}
        className={s.input}
        placeholder={placeholder}
        defaultValue={searchParams?.get('q') || ''}
        aria-label='Products search input'
        onKeyUp={handleKeyUp}
      />
      <Button
        onClick={onSearch}
        variant='brand'
        className={s.btn}
        type='button'
      >
        <Icon
          set1={<LoupeIcon />}
          set2={<LoupeIconSecond />}
          set3={<LoupeIconThird />}
          setNinelife={<LoupeIconNinelife />}
          setNinefit={<SearchNinefit />}
        />
        <span className='hidden md:block'>Search</span>
      </Button>
    </div>
  )
}

export default memo(Searchbar)
