import React from 'react'

export const MapIconThird = ({ ...props }) => (
  <svg
    role='img'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Map Icon</title>
    <path
      d='M14.9603 10.3998C14.9603 11.0788 14.6906 11.7299 14.2105 12.21C13.7304 12.6901 13.0793 12.9598 12.4003 12.9598C11.7214 12.9598 11.0702 12.6901 10.5901 12.21C10.11 11.7299 9.84033 11.0788 9.84033 10.3998C9.84033 9.72089 10.11 9.06974 10.5901 8.58965C11.0702 8.10956 11.7214 7.83984 12.4003 7.83984C13.0793 7.83984 13.7304 8.10956 14.2105 8.58965C14.6906 9.06974 14.9603 9.72089 14.9603 10.3998V10.3998Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.8 10.4C18.8 16.4945 12.4 20 12.4 20C12.4 20 6 16.4945 6 10.4C6 8.70261 6.67428 7.07475 7.87452 5.87452C9.07475 4.67428 10.7026 4 12.4 4C14.0974 4 15.7253 4.67428 16.9255 5.87452C18.1257 7.07475 18.8 8.70261 18.8 10.4V10.4Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
