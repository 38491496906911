import { FC } from 'react'
import { useBrandThemeContext } from '@contexts/brandThemeContext'

interface LogoProps {
  className: string
}

export const Logo: FC<LogoProps> = ({ className }) => {
  const { logo_url: logoUrl } = useBrandThemeContext()

  return (
    <div className={`relative ${className}`}>
      {logoUrl ? (
        <img
          width={180}
          height={32}
          className={className}
          src={logoUrl || ''}
          alt='Logo'
        />
      ) : null}
    </div>
  )
}
