import { StoreData } from 'models/storeData'
import { jsonApi } from '@spree/storefront-api-v2-sdk'

export interface StoreTag {
  id: string
  type: string
  attributes: {
    code: string
    type: string
  }
}

export const getStore = async (revalidate?: number): Promise<StoreData> => {
  const apiUrl = process.env.NEXT_PUBLIC_SPREE_API_HOST

  const res: any = await fetch(
    `${apiUrl}/api/custom/stores?include=default_country,store_tags`,
    { next: { revalidate } },
  )
  const resJson = await res.json()
  const storeTags: StoreTag[] = jsonApi.findRelationshipDocuments(
    resJson,
    resJson.data,
    'store_tags',
  )

  return {
    data: {
      ...resJson.data,
      countryName: resJson.included[0].attributes.name,
      countryISO: resJson.included[0].attributes.iso,
      zipRequired: resJson.included[0].attributes.zipcode_required,
      statesRequired: resJson.included[0].attributes.states_required,
      storeTags,
    },
  }
}
