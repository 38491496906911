import type { FunctionComponent } from 'react'
import {
  Provider,
  CommerceProviderProps,
  CoreCommerceProvider,
  useCommerce as useCoreCommerce,
} from '@commerce'
import { SWRConfig } from 'swr'
import useLogout from '@commerce/auth/use-logout'
import { spreeProvider, SpreeProvider } from './provider'
import handleTokenErrors from './utils/handle-token-errors'

export { spreeProvider }
export type { SpreeProvider }

export const WithTokenErrorsHandling: FunctionComponent = ({ children }) => {
  const logout = useLogout()

  return (
    <SWRConfig
      value={{
        onError: error => {
          handleTokenErrors(error, () => logout())
        },
      }}
    >
      {children}
    </SWRConfig>
  )
}

export const getCommerceProvider = <P extends Provider>(provider: P) =>
  function CommerceProvider({ children, ...props }: CommerceProviderProps) {
    return (
      <CoreCommerceProvider provider={{ ...provider, ...props }}>
        <WithTokenErrorsHandling>{children}</WithTokenErrorsHandling>
      </CoreCommerceProvider>
    )
  }

export const CommerceProvider =
  getCommerceProvider<SpreeProvider>(spreeProvider)

export const useCommerce = () => useCoreCommerce<SpreeProvider>()
