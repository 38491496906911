const DropDown = () => (
  <svg
    className='ml-2 h-4'
    width='12'
    height='6'
    viewBox='0 0 12 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.75 1.25L5.12895 4.20658C5.48952 4.52208 6.02321 4.53751 6.4014 4.24336L10.25 1.25'
      stroke='#0E3163'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
)

export default DropDown
