import { GetTaxonsProps, Taxon } from 'api/getTaxons'
import { NormalizedMenuTaxon } from '@models'

export const normalizeTaxonsForMenu = (
  taxons: GetTaxonsProps,
): NormalizedMenuTaxon[] => {
  const { included } = taxons

  return taxons.data.map(taxon => {
    const href = `/${taxon.attributes.permalink.replace('categories/', '')}`
    const image = taxons.included.find(
      item =>
        taxon.relationships.image.data?.id === item.id &&
        item.type == 'taxon_image',
    )

    return {
      name: taxon.attributes.name,
      href,
      id: taxon.id,
      description: taxon.attributes.description,
      metaDescription: taxon.attributes.meta_description,
      image: {
        original_url: image ? image.attributes.original_url : '',
      },
      dropDownItems: getSubItemsByIds(
        taxon.relationships.children.data,
        included,
      ),
    }
  })
}

const getSubItemsByIds = (
  ids: {
    id: string
    type: string
  }[],
  included: Taxon[],
) => {
  const subItems: NormalizedMenuTaxon[] = []

  ids.forEach(({ id }) => {
    const taxon = included.filter(i => i.id === id)[0]

    if (taxon) {
      subItems.push({
        name: taxon.attributes.name,
        href: `${taxon.attributes.permalink.slice(10)}`,
        id: taxon.id,
      })
    }
  })

  return subItems
}
