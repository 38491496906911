import { useState, useCallback } from 'react'
import cn from 'classnames'
import { toast } from 'react-toastify'
import { postSubscription } from 'api/postSubscription'
import { Button } from '@components/ui'
import { TOAST_OPTIONS } from '@constants/toastOptions'
import { emailPattern } from 'utils/validations'
import { debounce } from '@utils/helpers'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import s from './Subscription.module.css'

export const Subscription = () => {
  const [email, setEmail] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const {
    data: {
      attributes: { is_home_brand: isHomeBrand },
    },
  } = useStoreDataContext()

  const validation = (e: string) => {
    const isValidEmail = emailPattern.test(e)

    if (!isValidEmail && e) {
      setErrorMessage('Please enter a valid email')
    } else setErrorMessage('')
  }

  const debouncedValidation = useCallback(
    debounce((e: string) => {
      validation(e)
    }, 1000),
    [],
  )

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    const inputValue = e.target.value

    setEmail(inputValue)
    debouncedValidation(inputValue)
    if (!inputValue) {
      setTimeout(() => {
        setErrorMessage('Email is required')
      }, 1100)
    }
  }

  const onInputBlur = () => {
    const isValidEmail = emailPattern.test(email)

    if (!isValidEmail && email) {
      setErrorMessage('Please enter a valid email')
    }
    if (!email) {
      setErrorMessage('Email is required')
    }
  }

  const submitEmail = () => {
    setLoading(true)
    setErrorMessage('')
    const isValidEmail = emailPattern.test(email)

    if (!isValidEmail || !email) {
      setErrorMessage(!email ? 'Email is required' : 'Please enter valid email')
      setLoading(false)

      return
    }

    postSubscription({ email })
      .then(res => {
        toast.success(res.data.message, TOAST_OPTIONS)
        setEmail('')
      })
      .catch(() => {
        toast.error('Error!', TOAST_OPTIONS)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={s.subscribeForm}>
      <input
        type='email'
        value={email}
        onChange={onInputChange}
        onBlur={onInputBlur}
        aria-label='email input for subscribtion'
        className={cn(s.subscribeInput, {
          'placeholder:text-black': isHomeBrand,
        })}
        placeholder='Enter Email Address'
      />
      <Button
        type='submit'
        variant='brand'
        onClick={submitEmail}
        loading={loading}
        disabled={loading}
        className={s.subscribeSubmit}
      >
        Sign Up
      </Button>
      <p className='text-red pl-1 w-full absolute top-full left-0'>
        {errorMessage}
      </p>
    </div>
  )
}
