export const MapIcon = ({ ...props }) => (
  <svg
    role='img'
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Map Icon</title>
    <g clipPath='url(#clip0_2567_1529)'>
      <path
        d='M17.0833 2.57788L16.95 2.60288L12.5 4.32788L7.5 2.57788L2.8 4.16121C2.625 4.21955 2.5 4.36955 2.5
        4.56121V17.1612C2.5 17.3945 2.68333 17.5779 2.91667 17.5779L3.05 17.5529L7.5 15.8279L12.5 17.5779L17.2
        15.9945C17.375 15.9362 17.5 15.7862 17.5 15.5945V2.99455C17.5 2.76121 17.3167 2.57788 17.0833 2.57788ZM12.5
        15.9112L7.5 14.1529V4.24455L12.5 6.00288V15.9112Z'
      />
    </g>
    <defs>
      <clipPath id='clip0_2567_1529'>
        <rect
          width='20'
          height='20'
          fill='white'
          transform='translate(0 0.0778809)'
        />
      </clipPath>
    </defs>
  </svg>
)
