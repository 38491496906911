import {
  FC,
  MutableRefObject,
  KeyboardEvent,
  useEffect,
  useRef,
  ReactNode,
} from 'react'
import cn from 'classnames'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import s from './Sidebar.module.css'

interface SidebarProps {
  children: ReactNode,
  onClose: () => void,
}

const Sidebar: FC<SidebarProps> = ({ children, onClose }) => {
  const sidebarRef = useRef() as MutableRefObject<HTMLDivElement>
  const contentRef = useRef() as MutableRefObject<HTMLDivElement>

  const onKeyDownSidebar = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.focus()
    }

    const contentElement = contentRef.current

    if (contentElement) {
      disableBodyScroll(contentElement, { reserveScrollBarGap: true })
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <div
      className={cn(s.root)}
      ref={sidebarRef}
      onKeyDown={onKeyDownSidebar}
      role='none'
    >
      <div className='absolute inset-0 overflow-hidden'>
        <div className={s.backdrop} onClick={onClose} role='none' />
        <section
          className={cn(
            'absolute inset-y-0 right-0 flex outline-none z-20 md:pr-10 left-0',
            s.sidebarSection,
          )}
        >
          <div className='h-full w-full md:w-screen md:max-w-md'>
            <div className={s.sidebar} ref={contentRef}>
              {children}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Sidebar
