import { GOOGLE_ANALYTICS_TAG_TYPE } from '@constants/common'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { CookiesAcceptModes } from '@lib/hooks/useAcceptCookies'
import { GoogleTagManager } from '@next/third-parties/google'
import Script from 'next/script'
import { Fragment } from 'react'

export const GAScripts = () => {
  const {
    data: { storeTags },
  } = useStoreDataContext()

  return (
    <>
      {Array.isArray(storeTags) && process.env.NEXT_PUBLIC_CONSENT_MODE_ENABLED &&
        storeTags.map(el => (
          <Script
            id={`${el.id}-consent`}
            key={el.id}
          >
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('consent', 'default', {
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'wait_for_update': 10000,
          });
          gtag('js', new Date());
          gtag('config', '${el.attributes.code.trim()}');
          `}
          </Script>
        ))}

      {Array.isArray(storeTags) &&
        storeTags.map(({ id, attributes: { code, type } }) => {
          switch (type) {
            case GOOGLE_ANALYTICS_TAG_TYPE.GOOGLE_ADS:
              return (
                <Fragment key={id}>
                  <GoogleTagManager gtmId={code.trim()} />

                  <Script id={id}>
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${code.trim()}', {'allow_enhanced_conversions': true});
                  `}
                  </Script>
                </Fragment>
              )
            case GOOGLE_ANALYTICS_TAG_TYPE.UA:
              return (
                <Fragment key={id}>
                  <GoogleTagManager gtmId={code.trim()} />
                  <Script
                    id={id}
                    dangerouslySetInnerHTML={{
                      __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date()); gtag('config', '${code.trim()}');
                      `,
                    }}
                  />
                </Fragment>
              )
            case GOOGLE_ANALYTICS_TAG_TYPE.GTM:
              return (
                <Fragment key={id}>
                  <Script id={id}>
                    {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${code.trim()}');
                    `}
                  </Script>
                  <Script id={`${id}-following`}>
                    {`(function () {
                    const body = document.body;
                    const ns = document.createElement('noscript');
                    const iframe = document.createElement('iframe');
                    iframe.src='https://www.googletagmanager.com/ns.html?id=${code.trim()}'
                    iframe.height = 0;
                    iframe.width = 0;
                    iframe.style='display:none;visibility:hidden'
                    ns.appendChild(iframe)
                    body.appendChild(ns);
                  })()
                    `}
                  </Script>
                </Fragment>
              )
            default:
              return null
          }
        })}
    </>
  )
}
