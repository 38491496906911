export const EnvelopeIcon = ({ ...props }) => (
  <svg
    height='25px'
    width='25px'
    fill='currentColor'
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512.000000 512.000000'
    {...props}
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      stroke='none'
    >
      <path
        d='M505 4495 c-135 -30 -231 -82 -322 -175 -70 -70 -115 -143 -151 -242
        l-27 -73 0 -1445 0 -1445 27 -73 c73 -200 221 -343 418 -404 l75 -23 2035 0
        2035 0 75 23 c197 61 345 204 418 404 l27 73 0 1445 0 1445 -27 73 c-72 197
        -217 338 -413 404 l-70 23 -2020 2 c-1663 1 -2031 -1 -2080 -12z m4137 -313
        c73 -36 124 -87 160 -161 l23 -46 3 -1402 c2 -1395 2 -1402 -18 -1457 -27 -72
        -94 -144 -167 -181 l-58 -30 -2025 0 -2025 0 -50 25 c-74 36 -148 114 -173
        183 l-22 58 0 1388 c0 1536 -5 1428 65 1523 41 55 95 94 168 119 49 18 150 18
        2052 16 l2000 -2 67 -33z'
      />
      <path
        d='M694 3959 c-73 -21 -118 -104 -95 -178 10 -35 91 -111 657 -616 355
        -318 644 -581 642 -585 -2 -4 -289 -275 -638 -602 -349 -327 -641 -606 -648
        -622 -42 -93 30 -200 135 -198 24 0 54 7 66 15 12 8 311 285 665 616 l643 602
        172 -154 c95 -84 184 -162 199 -171 33 -22 111 -23 141 -3 12 8 103 87 202
        175 l179 161 26 -23 c14 -13 305 -289 648 -614 661 -627 653 -621 731 -606 46
        10 98 62 106 108 17 86 34 66 -656 721 -610 580 -639 609 -622 625 10 9 298
        268 640 574 544 487 624 562 634 597 16 53 -1 108 -46 148 -30 26 -44 31 -89
        31 -40 0 -61 -6 -82 -22 -23 -18 -1619 -1446 -1715 -1535 l-27 -24 -866 775
        c-476 427 -876 782 -889 790 -31 20 -77 26 -113 15z'
      />
    </g>
  </svg>
)
