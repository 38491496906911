import { ENGLISH_ALPHABETS_VALIDATION_MESSAGE } from '@constants/common'

export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,}$/
export const phonePattern = /^\+?[0-9][0-9]{6,14}$/

// regex to match latin-1 charset unicode values are taken from https://en.wikipedia.org/wiki/Latin-1_Supplement and https://en.wikipedia.org/wiki/Basic_Latin_(Unicode_block)
// mathes numbers, letters, empty space and Hyphen-minus
export const allowedCharacters = {
  name: /[\u0020\u0009\u0027\u002d\u0041-\u005a\u0060-\u007a\u00c0-\u00ff\u0100\u0101\u0112\u0113\u012a\u012b\u014c\u014d\u0232\u0233\u1e9e\u0152\u0153\u016a\u016b\u0174-\u0178\u2019]/g,
  company: /[\u0020\u0009\u0022\u0026-\u0029\u002c-\u003b\u0041-\u005a\u005f-\u007a\u00c0-\u00ff\u0100\u0101\u0112\u0113\u012a\u012b\u014c\u014d\u0232\u0233\u1e9e\u0152\u0153\u016a\u016b\u0174-\u0178\u2013-\u201e]/g,
  address: /[\u0009\u0020-\u0023\u0026-\u003b\u0040-\u005a\u0061-\u007e\u00c0-\u00ff\u0100\u0101\u0112\u0113\u012a\u012b\u014c\u014d\u0232\u0233\u1e9e\u0152\u0153\u016a\u016b\u0174-\u0178\u2013-\u201e]/g,
}

export const validateEnglishAlphabets = (
  value: string,
  allowedCharactersPattern: RegExp,
  isRequired: boolean,
) => {
  if (!isRequired && !value) return true

  const matchedText = value.match(allowedCharactersPattern)

  if (matchedText === null || (matchedText && matchedText.join('') !== value))
    return ENGLISH_ALPHABETS_VALIDATION_MESSAGE

  return true
}
