export const UserIcon = ({ ...props }) => (
  <svg
    role='img'
    width='33'
    height='33'
    viewBox='0 0 33 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>User Avatar Icon</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.3111 24.1794C23.9778 27.3109 20.246 29.339 16.0406 29.339C11.8352 29.339 8.10353 27.311 5.77017 24.1797C7.9688
      20.5291 11.7569 18.1391 16.0407 18.1391C20.3245 18.1391 24.1124 20.529 26.3111 24.1794ZM26.9648 23.2135C24.9166 20.0594
      21.7185 17.822 18.0137 17.2288C19.9825 16.4444 21.3739 14.5209 21.3739 12.2724C21.3739 9.32688 18.9861 6.93906 16.0406
      6.93906C13.0951 6.93906 10.7073 9.32688 10.7073 12.2724C10.7073 14.5209 12.0987 16.4444 14.0675 17.2289C10.3627 17.8221
      7.16463 20.0595 5.11647 23.2137C3.92649 21.2703 3.24056 18.9848 3.24056 16.539C3.24056 9.46979 8.97131 3.73903 16.0406
      3.73903C23.1098 3.73903 28.8406 9.46979 28.8406 16.539C28.8406 18.9847 28.1547 21.2701 26.9648 23.2135ZM29.9072
      16.539C29.9072 24.1974 23.6989 30.4057 16.0406 30.4057C8.38221 30.4057 2.17389 24.1974 2.17389 16.539C2.17389
      8.88068 8.38221 2.67236 16.0406 2.67236C23.6989 2.67236 29.9072 8.88068 29.9072 16.539ZM16.0406 16.5391C18.397
      16.5391 20.3073 14.6288 20.3073 12.2724C20.3073 9.91598 18.397 8.00573 16.0406 8.00573C13.6842 8.00573 11.7739
      9.91598 11.7739 12.2724C11.7739 14.6288 13.6842 16.5391 16.0406 16.5391Z'
      fill='black'
    />
  </svg>
)
