import React, { FC } from 'react'
import { EnvelopeIcon } from '@components/icons'
import { EnvelopeIconSecond } from '@components/iconsSetTwo'
import { Icon } from '@components/common'
import { EnvelopeIconThird } from '@components/iconsSetThree'
import { EnvelopeNinefit } from '@components/iconSetNinefit/EnvelopeNinefit'
import { MailCopperStudio } from '@components/iconSetCopperStudio/MailIconCopperStudio'

export interface EmailProps {
  value?: string
  linkStyles?: string
  iconStyles?: string
  auditId?: string
}

const Email: FC<EmailProps> = ({ value, linkStyles, iconStyles, auditId }) => {
  const envelopeIconComponent = (
    <Icon
      set1={
        <EnvelopeIcon
          alt='Support email envelope icon'
          className={iconStyles}
        />
      }
      set2={
        <EnvelopeIconSecond
          alt='Support email envelope icon'
          className={iconStyles}
        />
      }
      set3={
        <EnvelopeIconThird
          alt='Support email envelope icon'
          className={iconStyles}
        />
      }
      setNinefit={
        <EnvelopeNinefit
          alt='Support email envelope icon'
          className={`${iconStyles}`}
        />
      }
      setNinelife={
        <EnvelopeIcon
          alt='Support email envelope icon'
          className={`${iconStyles}`}
        />
      }
      setCopperStudio={
        <MailCopperStudio
          alt='Support email envelope icon'
          className={`${iconStyles}`}
        />
      }
    />
  )

  if (!value) return null

  return (
    <a
      // eslint-disable-next-line react/no-unknown-property
      data-testid={auditId}
      href={`mailto:${value}`}
      target='_blank'
      rel='noreferrer'
      className={linkStyles}
      title={value}
    >
      {iconStyles && envelopeIconComponent}
      {value}
    </a>
  )
}

export default Email
