import { FC } from 'react'
import { Sidebar, useUI } from '@components/ui'
import MenuSidebarView from '@components/common/UserNav/MenuSidebarView'

export const SidebarUI: FC = () => {
  const { displaySidebar, closeSidebar } = useUI()

  return displaySidebar ? (
    <Sidebar onClose={closeSidebar}>
      <MenuSidebarView />
    </Sidebar>
  ) : (
    <></>
  )
}
