import React from 'react'

export const LoupeIconNinelife = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Search Icon</title>
    <g clipPath='url(#clip0_6429_31366)'>
      <path
        d='M22.0803 19.8093C23.6537 17.6622 24.3585 15.0001 24.0535 12.3557C23.7485 9.71128 22.4563 7.27952 20.4354 5.54693C18.4144 3.81434 15.8138 2.9087 13.1539 3.0112C10.4939 3.11369 7.97068 4.21676 6.08907 6.09972C4.20745 7.98268 3.10619 10.5067 3.0056 13.1667C2.90501 15.8268 3.81251 18.4267 5.54654 20.4464C7.28058 22.4661 9.71327 23.7566 12.3579 24.0597C15.0026 24.3628 17.6641 23.6561 19.8101 22.0811H19.8085C19.8573 22.1461 19.9093 22.2078 19.9678 22.268L26.224 28.5242C26.5287 28.8291 26.9421 29.0005 27.3731 29.0007C27.8042 29.0008 28.2177 28.8297 28.5226 28.525C28.8275 28.2203 28.9989 27.807 28.999 27.3759C28.9992 26.9449 28.8281 26.5314 28.5234 26.2265L22.2671 19.9702C22.209 19.9114 22.1466 19.8587 22.0803 19.8093ZM22.4995 13.5628C22.4995 14.7365 22.2683 15.8987 21.8192 16.9831C21.37 18.0674 20.7117 19.0527 19.8818 19.8826C19.0519 20.7125 18.0666 21.3709 16.9822 21.82C15.8979 22.2692 14.7357 22.5003 13.562 22.5003C12.3883 22.5003 11.2261 22.2692 10.1418 21.82C9.05743 21.3709 8.07216 20.7125 7.24224 19.8826C6.41232 19.0527 5.75398 18.0674 5.30483 16.9831C4.85568 15.8987 4.62451 14.7365 4.62451 13.5628C4.62451 11.1925 5.56613 8.91919 7.24224 7.24308C8.91835 5.56697 11.1916 4.62535 13.562 4.62535C15.9324 4.62535 18.2057 5.56697 19.8818 7.24308C21.5579 8.91919 22.4995 11.1925 22.4995 13.5628Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_6429_31366'>
        <rect
          width='26'
          height='26'
          fill='currentColor'
          transform='translate(3 3)'
        />
      </clipPath>
    </defs>
  </svg>
)
