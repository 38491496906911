import { getCmsPages } from '@api/getCmsPages'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { CmsPage, BANNER_ELEMENT_TYPES } from '@models'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

const checkoutPages = [
  '/shipping-details',
  '/payment-details',
  '/shipping-method',
  '/order-confirmation',
]

export const FooterBanner = () => {
  const {
    data: {
      attributes: { is_home_brand: isHomeBrand },
    },
  } = useStoreDataContext()
  const pathname = usePathname()

  const [footerBanner, setFooterBanner] = useState<CmsPage | undefined | null>(
    null,
  )

  const getFooterBanner = async () => {
    const data = await getCmsPages({ perPage: 1, page: 'homepage' })
    const pageBanners =
      (data.included.map(el => ({
        attributes: {
          ...el.attributes,
        },
      })) as CmsPage[]) || []
    const banner = pageBanners.find(
      el =>
        el.attributes.content.banner_element ===
        BANNER_ELEMENT_TYPES.FOOTER_BANNER,
    )

    setFooterBanner(banner)
  }

  useEffect(() => {
    getFooterBanner
  })

  return footerBanner &&
    isHomeBrand &&
    !checkoutPages.includes(pathname || '') ? (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('${footerBanner.attributes.img_one_lg}'), lightgray 50% / cover no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
      className='px-10 sm:px-40 py-10 w-full text-white'
    >
      <div className='text-center max-w-[1240px] my-0 mx-auto'>
        {footerBanner.attributes.content.subtitle}
      </div>
    </div>
  ) : null
}
