export const BagIconSecond = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Bag Icon</title>
    <path
      d='M9.33301 29.3333C8.59967 29.3333 7.97212 29.0724 7.45034 28.5507C6.92767 28.028 6.66634 27.4 6.66634
        26.6667C6.66634 25.9333 6.92767 25.3053 7.45034 24.7827C7.97212 24.2609 8.59967 24 9.33301 24C10.0663 24
        10.6939 24.2609 11.2157 24.7827C11.7383 25.3053 11.9997 25.9333 11.9997 26.6667C11.9997 27.4 11.7383 28.028
        11.2157 28.5507C10.6939 29.0724 10.0663 29.3333 9.33301 29.3333ZM22.6663 29.3333C21.933 29.3333 21.3055 29.0724
        20.7837 28.5507C20.261 28.028 19.9997 27.4 19.9997 26.6667C19.9997 25.9333 20.261 25.3053 20.7837 24.7827C21.3055
        24.2609 21.933 24 22.6663 24C23.3997 24 24.0277 24.2609 24.5503 24.7827C25.0721 25.3053 25.333 25.9333 25.333
        26.6667C25.333 27.4 25.0721 28.028 24.5503 28.5507C24.0277 29.0724 23.3997 29.3333 22.6663 29.3333ZM8.19967
        8L11.3997 14.6667H20.733L24.3997 8H8.19967ZM6.93301 5.33334H26.5997C27.1108 5.33334 27.4997 5.56089 27.7663
        6.01601C28.033 6.472 28.0441 6.93334 27.7997 7.4L23.0663 15.9333C22.8219 16.3778 22.4939 16.7222 22.0823
        16.9667C21.6717 17.2111 21.2219 17.3333 20.733 17.3333H10.7997L9.33301 20H25.333V22.6667H9.33301C8.33301
        22.6667 7.57745 22.2276 7.06634 21.3493C6.55523 20.472 6.53301 19.6 6.99967 18.7333L8.79967 15.4667L3.99967
        5.33334H1.33301V2.66667H5.66634L6.93301 5.33334ZM11.3997 14.6667H20.733H11.3997Z'
    />
  </svg>
)
