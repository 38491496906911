export const WishlistIconCopperStudio = (props: any) => (
  <svg
    width='28'
    height='24'
    viewBox='0 0 28 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.1337 23.7333C13.467 23.7333 12.667 23.4667 12.1337 22.9333C3.46699 15.3333 3.33366 15.2 3.33366 15.0667L3.20032 14.9333C1.60032 13.3333 0.666992 11.0667 0.666992 8.8V8.53333C0.800326 3.73333 4.66699 0 9.46699 0C10.9337 0 12.9337 0.8 14.1337 2.4C15.3337 0.8 17.467 0 18.9337 0C23.7337 0 27.467 3.73333 27.7337 8.53333V8.8C27.7337 11.2 26.8003 13.3333 25.2003 15.0667L25.067 15.2C24.9337 15.3333 23.867 16.2667 16.267 23.0667C15.6003 23.4667 14.9337 23.7333 14.1337 23.7333ZM5.33366 14.6667C5.86699 15.2 8.53366 17.0667 13.467 21.3333C13.867 21.7333 14.4003 21.7333 14.8003 21.3333C19.867 16.8 22.8003 14.2667 23.467 13.7333L23.6003 13.6C24.9337 12.2667 25.6003 10.5333 25.6003 8.8V8.53333C25.467 4.8 22.5337 2 18.8003 2C17.867 2 16.0003 2.66667 15.3337 4.13333C15.067 4.66667 14.5337 4.93333 14.0003 4.93333C13.467 4.93333 12.9337 4.66667 12.667 4.13333C12.0003 2.8 10.267 2 9.20032 2C5.60032 2 2.53366 4.93333 2.40032 8.53333V8.93333C2.40032 10.6667 3.20032 12.4 4.40032 13.6L5.33366 14.6667Z'
      fill='black'
    />
  </svg>
)
