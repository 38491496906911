const Cross = ({ ...props }) => (
  <svg
    viewBox='0 0 24 24'
    width='24'
    height='24'
    stroke='currentColor'
    strokeWidth={props.isHomeBrand ? '2.5' : '1.5'}
    fill='none'
    shapeRendering='geometricPrecision'
    {...props}
  >
    <path d='M18 6L6 18' />
    <path d='M6 6l12 12' />
  </svg>
)

export default Cross
