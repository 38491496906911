export const MailCopperStudio = (props: any) => (
  <svg
    width='20'
    height='17'
    viewBox='0 0 20 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 0H4C1.79086 0 0 1.79086 0 4V13C0 15.2091 1.79086 17 4 17H16C18.2091 17 20 15.2091 20 13V4C20 1.79086 18.2091 0 16 0ZM4 1.59H16C17.0657 1.59204 18.0025 2.29663 18.3 3.32L10.76 8.91C10.5534 9.11204 10.2732 9.22095 9.98433 9.21151C9.69548 9.20207 9.42301 9.0751 9.23 8.86L1.72 3.33C2.01175 2.30973 2.9389 1.60216 4 1.59ZM1.59 13C1.59 14.331 2.66899 15.41 4 15.41H16C17.3271 15.4045 18.4 14.3271 18.4 13V4.97L11.6 9.97C11.1654 10.3746 10.5938 10.5997 10 10.6C9.38274 10.5902 8.79115 10.3514 8.34 9.93L1.59 4.93V13Z'
      fill='currentColor'
    />
  </svg>
)
