export const UserIconNinelife = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='currentColor'
    {...props}
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>User Avatar Icon</title>
    <g clipPath='url(#clip0_6435_31466)'>
      <rect width='28' height='28' transform='translate(2 2)' fill='white' />
      <path
        d='M21.25 12.5C21.25 13.8924 20.6969 15.2277 19.7123 16.2123C18.7277 17.1969 17.3924 17.75 16 17.75C14.6076 17.75 13.2723 17.1969 12.2877 16.2123C11.3031 15.2277 10.75 13.8924 10.75 12.5C10.75 11.1076 11.3031 9.77226 12.2877 8.78769C13.2723 7.80312 14.6076 7.25 16 7.25C17.3924 7.25 18.7277 7.80312 19.7123 8.78769C20.6969 9.77226 21.25 11.1076 21.25 12.5Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 16C2 12.287 3.475 8.72601 6.10051 6.10051C8.72601 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.10051C28.525 8.72601 30 12.287 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30C12.287 30 8.72601 28.525 6.10051 25.8995C3.475 23.274 2 19.713 2 16ZM16 3.75C13.6931 3.75012 11.4331 4.40163 9.4802 5.62955C7.52727 6.85746 5.96074 8.61187 4.96094 10.6908C3.96113 12.7698 3.56868 15.0888 3.82875 17.381C4.08882 19.6732 4.99084 21.8454 6.431 23.6475C7.6735 21.6455 10.4088 19.5 16 19.5C21.5912 19.5 24.3248 21.6437 25.569 23.6475C27.0092 21.8454 27.9112 19.6732 28.1713 17.381C28.4313 15.0888 28.0389 12.7698 27.0391 10.6908C26.0393 8.61187 24.4727 6.85746 22.5198 5.62955C20.5669 4.40163 18.3069 3.75012 16 3.75Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_6435_31466'>
        <rect width='28' height='28' fill='white' transform='translate(2 2)' />
      </clipPath>
    </defs>
  </svg>
)
