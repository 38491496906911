import { FC } from 'react'
import cn from 'classnames'
import { useUI } from '@components/ui/context'
import s from './HamburgerMenu.module.css'

const HamburgerMenu: FC<{
  isShowHamburgerMenu: boolean
  isHomeBrand: boolean
}> = ({ isShowHamburgerMenu, isHomeBrand }) => {
  const { toggleSidebar, openSidebar } = useUI()

  const handleOnClickMenu = () => {
    openSidebar()
    toggleSidebar()
  }

  return (
    <button
      style={{ display: isShowHamburgerMenu ? 'initial' : 'none' }}
      type='button'
      onClick={handleOnClickMenu}
      aria-label='Menu'
    >
      {/* eslint-disable-next-line react/no-unknown-property*/}
      {!isHomeBrand ? (
        <div
          data-testid='hamburger_menu_button'
          className={cn(s.navIcon, { [s.navIconHome]: isHomeBrand })}
        >
          <span />
          <span />
          <span />
          <span />
        </div>
      ) : (
        <svg
          className='sm:w-8 sm:h-8 w-6 h-6'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M5.5 9.5H26.5M5.5 16H26.5M5.5 22.5H26.5'
            stroke='black'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
          />
        </svg>
      )}
    </button>
  )
}

export default HamburgerMenu
