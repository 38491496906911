import React from 'react'

export const UserThird = ({ ...props }) => (
  <svg
    role='img'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>User Avatar Icon</title>
    <circle cx='12' cy='12' r='10.3' stroke='currentColor' strokeWidth='1.4' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.7832 17.238C17.7832 19.7643 14.359 20.0563 11.784 20.0563L11.5997 20.0561C9.95912 20.0521 5.7832 19.9473 5.7832 17.2227C5.7832 14.7479 9.06983 14.4172 11.6252 14.4047L11.9682 14.4045C13.6087 14.4085 17.7832 14.5133 17.7832 17.238ZM11.784 15.554C8.55593 15.554 6.91957 16.1151 6.91957 17.2227C6.91957 18.3402 8.55593 18.9066 11.784 18.9066C15.0112 18.9066 16.6468 18.3455 16.6468 17.238C16.6468 16.1205 15.0112 15.554 11.784 15.554ZM11.784 4.82617C14.0021 4.82617 15.8059 6.65191 15.8059 8.89615C15.8059 11.1404 14.0021 12.9654 11.784 12.9654H11.7597C9.54608 12.9585 7.7529 11.1319 7.76045 8.89385C7.76045 6.65191 9.56502 4.82617 11.784 4.82617ZM11.784 5.9207C10.162 5.9207 8.84228 7.25513 8.84228 8.89615C8.83699 10.5318 10.1468 11.8655 11.762 11.8716L11.784 12.4189V11.8716C13.4052 11.8716 14.7241 10.5364 14.7241 8.89615C14.7241 7.25513 13.4052 5.9207 11.784 5.9207Z'
      fill='currentColor'
    />
  </svg>
)
