const Bus = ({ ...props }) => (
  <svg
    role='img'
    width='63'
    height='50'
    viewBox='0 0 63 50'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Delivery Truck Icon</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.2001 4.93328H31.5001C34.1142 4.93328 36.2334 7.05247 36.2334 9.66662L36.2334 26.2333H5.46673V9.66662C5.46673
      7.05247 7.58592 4.93328 10.2001 4.93328ZM5.46673 30.9666L36.2334 30.9666L36.2334 35.6999H24.2805C22.7865 34.2359 20.7404
      33.3333 18.4834 33.3333C16.2264 33.3333 14.1803 34.2359 12.6863 35.6999H10.2001C7.58592 35.6999 5.46673 33.5808 5.46673
      30.9666ZM38.6001 40.4333H26.6828C26.7381 40.8198 26.7667 41.2148 26.7667 41.6166C26.7667 46.1914 23.0582 49.8999 18.4834
      49.8999C13.9086 49.8999 10.2001 46.1914 10.2001 41.6166C10.2001 41.2148 10.2287 40.8198 10.284 40.4333H10.2001C4.97177
      40.4333 0.733398 36.1949 0.733398 30.9666V9.66662C0.733398 4.43832 4.97177 0.199951 10.2001 0.199951H31.5001C35.9112
      0.199951 39.6176 3.21692 40.6685 7.29995L45.6977 7.29995C45.6985 7.29995 45.6993 7.29995 45.7001 7.29995C45.7008 7.29995
      45.7016 7.29995 45.7024 7.29995H46.9597C48.3757 7.29993 49.6344 7.2999 50.704 7.38062C51.8055 7.46376 52.8886 7.64203 53.9092
      8.0956C56.0671 9.05468 57.2677 10.9431 58.4669 13.3415C60.4094 17.2266 61.7148 20.6434 62.1453 21.9349L62.2667 22.2992V32.15C62.2667
      34.3287 61.7152 36.1418 60.6093 37.5387C59.6835 38.7081 58.505 39.4142 57.3423 39.8393C57.4674 40.4119 57.5334 41.0065 57.5334
      41.6166C57.5334 46.1914 53.8248 49.8999 49.2501 49.8999C44.6753 49.8999 40.9667 46.1914 40.9667 41.6166C40.9667 41.2148
      40.9953 40.8198 41.0506 40.4333H40.9667H38.6001ZM40.9667 35.7H43.4529C44.9469 34.2359 46.9931 33.3333 49.2501 33.3333C51.4577
      33.3333 53.4637 34.1969 54.9485 35.6048C55.1051 35.5735 55.2572 35.5371 55.4041 35.4952C56.1161 35.2917 56.5889 34.9914
      56.8981 34.6007C57.1974 34.2227 57.5334 33.5212 57.5334 32.15L57.5334 26.2333H48.0667C45.4526 26.2333 43.3334 24.1141 43.3334
      21.5V12.0333H40.9667V35.7ZM48.0667 12.0351V21.5H56.9371C56.3306 19.9666 55.4089 17.8097 54.2333 15.4584C53.0658 13.1235
      52.4914 12.6452 51.9868 12.421C51.6761 12.2829 51.2061 12.1653 50.3477 12.1005C49.7228 12.0534 48.9893 12.0393 48.0667
      12.0351ZM10.2001 12.0333C10.2001 10.7262 11.2597 9.66662 12.5667 9.66662H29.1334C30.4405 9.66662 31.5001 10.7262 31.5001
      12.0333C31.5001 13.3404 30.4405 14.4 29.1334 14.4H12.5667C11.2597 14.4 10.2001 13.3404 10.2001 12.0333ZM12.5667 16.7666C11.2597
      16.7666 10.2001 17.8262 10.2001 19.1333C10.2001 20.4404 11.2597 21.5 12.5667 21.5H29.1334C30.4405 21.5 31.5001 20.4404 31.5001
      19.1333C31.5001 17.8262 30.4405 16.7666 29.1334 16.7666H12.5667ZM18.4834 45.1666C20.444 45.1666 22.0334 43.5772 22.0334
      41.6166C22.0334 39.656 20.444 38.0666 18.4834 38.0666C16.5228 38.0666 14.9334 39.656 14.9334 41.6166C14.9334 43.5772 16.5228
      45.1666 18.4834 45.1666ZM52.8001 41.6166C52.8001 43.5772 51.2107 45.1666 49.2501 45.1666C47.2895 45.1666 45.7001 43.5772
      45.7001 41.6166C45.7001 39.656 47.2895 38.0666 49.2501 38.0666C51.2107 38.0666 52.8001 39.656 52.8001 41.6166Z'
    />
  </svg>
)

export default Bus
