import { useHook, useMutationHook } from '@commerce/utils/use-hook'
import { mutationFetcher } from '@commerce/utils/default-fetcher'
import type { HookFetcherFn, MutationHook } from '@commerce/utils/types'
import type { LogoutHook } from '@commerce/types/logout'
import type { Provider } from '@commerce'

export type UseLogout<
  H extends MutationHook<LogoutHook<any>> = MutationHook<LogoutHook>,
> = ReturnType<H['useHook']>

export const fetcher: HookFetcherFn<LogoutHook> = mutationFetcher

const fn = (provider: Provider) => provider.auth.useLogout!

const useLogout: UseLogout = (...args) => {
  const hook = useHook(fn)

  return useMutationHook({ fetcher, ...hook })(...args)
}

export default useLogout
