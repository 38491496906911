import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { BaseSyntheticEvent, FC, useCallback, useState } from 'react'
import cn from 'classnames'
import { useUI } from '@components/ui/context'

import { Cross } from '@components/icons'
import DropDown from '@components/icons/DropDown'
import { Logo } from '@components/ui'
import { NormalizedMenuTaxon } from '@models'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { useTaxonsContext } from '@contexts/taxonsContext'
import s from './MenuSidebarView.module.css'
import { CopperStudioHeader } from './CopperStudioHeader'

const MenuSidebarView: FC = () => {
  const taxons = useTaxonsContext()
  const { data } = useStoreDataContext()
  const [openSubmenu, setOpenSubmenu] = useState('')
  const { closeSidebar } = useUI()
  const { push } = useRouter()
  const pathname = usePathname()

  const closeMenu = useCallback(
    async e => {
      if (e.target.href) await push(e.target.href)
      closeSidebar()
    },
    [closeSidebar, push],
  )

  const handleShowMenu = useCallback((e: BaseSyntheticEvent) => {
    const { id } = e.currentTarget

    setOpenSubmenu(id)
  }, [])

  const isHomeBrand = data.attributes.is_home_brand
  const [, openName] = openSubmenu.split('_')

  const links = [
    {
      name: 'All Categories',
      href: '/all-categories',
      id: '0',
    },
    {
      name: isHomeBrand ? 'Trending this month' : 'Bestsellers',
      href: '/bestsellers',
      id: '1',
    },
  ] as NormalizedMenuTaxon[]

  return (
    <div
      className={cn(s.LayoutRoot, {
        '!bg-[#2A1A11] sm:!bg-white': isHomeBrand,
      })}
    >
      {isHomeBrand ? (
        <CopperStudioHeader closeSidebar={closeSidebar} />
      ) : (
        <header
          className={cn(
            {
              'sm:!justify-center !justify-end !bg-[#2A1A11] sm:!bg-white':
                isHomeBrand,
            },
            s.header,
          )}
        >
          <Logo className={cn('!w-[180px] object-contain h-full')} />
          <div
            className={cn(
              'w-10 h-10 rounded-full border-2 border-blueText sm:border-0 flex items-center justify-center sm:mt-3 mr-5 ',
              { 'sm:hidden border-white': isHomeBrand },
            )}
          >
            <Cross
              className={cn(s.cross, { '!text-white !w-4 !h-4': isHomeBrand })}
              onClick={() => closeSidebar()}
            />
          </div>
        </header>
      )}

      <ul className='mb-10 flex sm:hidden items-start max-w-full overflow-x-scroll gap-x-5 px-6 pb-1 sm:pb-0'>
        {taxons.map(taxon => (
          <li key={taxon.id} onClick={closeMenu} onKeyDown={closeMenu}>
            <Link
              data-testid='sidebar_menu_link'
              className={s.category}
              href={taxon.href}
            >
              <div className={s.categoryImage}>
                {taxon.image && (
                  <img
                    width={150}
                    height={150}
                    className='rounded-brand'
                    src={`${taxon.image.original_url}`}
                    alt={taxon.name}
                  />
                )}
              </div>
              <span className={s.categoryName}>{taxon.name}</span>
            </Link>
          </li>
        ))}
      </ul>
      <div className={s.container}>
        {openName && (
          <div className={s.targetLevel}>
            <div className={s.targetHeader}>
              <div
                role='button'
                className='flex items-center cursor-pointer'
                onClick={() => setOpenSubmenu('')}
                onKeyDown={() => setOpenSubmenu('')}
              >
                <span className={s.menuIconReverse}>
                  <DropDown />
                </span>
                <span className='text-white sm:text-black'>{openName}</span>
              </div>
            </div>
          </div>
        )}
        <div className={s.root}>
          {isHomeBrand ? (
            <div
              className='py-1 px-0 my-5 justify-between items-center hidden sm:flex'
              style={{
                boxShadow: '0px -1px 0px 0px #C4C4C4 inset',
              }}
            >
              <h2
                style={{
                  lineHeight: '30px',
                }}
              >
                Categories
              </h2>
              <div className='rounded-full border-2 border-blueText sm:border-0 flex items-center justify-center'>
                <Cross
                  isHomeBrand
                  className={s.cross}
                  onClick={() => closeSidebar()}
                />
              </div>
            </div>
          ) : null}
          <nav>
            <ul>
              {links?.map(link => (
                <li
                  key={link.href}
                  onClick={closeMenu}
                  onKeyDown={closeMenu}
                  className={cn(s.menuItem, {
                    [s.openChild]:
                      openSubmenu === `${link.id}_${link.name}` &&
                      link.dropDownItems,
                    [s.isActive]:
                      (!openSubmenu && link.dropDownItems) ||
                      (!openSubmenu && !link.dropDownItems) ||
                      openSubmenu === `${link.id}_${link.name}`,
                  })}
                >
                  <Link data-testid='sidebar_menu_link' href={link.href}>
                    {link.name}
                  </Link>
                </li>
              ))}
              {taxons.map((taxon: NormalizedMenuTaxon) => (
                <li
                  key={taxon.href}
                  className={cn(s.menuItem, {
                    [s.openChild]:
                      openSubmenu === `${taxon.id}_${taxon.name}` &&
                      taxon.dropDownItems,
                    [s.isActive]:
                      (!openSubmenu && taxon.dropDownItems) ||
                      (!openSubmenu && !taxon.dropDownItems) ||
                      openSubmenu === `${taxon.id}_${taxon.name}`,
                  })}
                >
                  {taxon.href &&
                  (!taxon.dropDownItems || taxon.dropDownItems.length === 0) ? (
                    <Link data-testid='sidebar_menu_link' href={taxon.href}>
                      {taxon.name}
                    </Link>
                  ) : (
                    <span
                      id={`${taxon.id}_${taxon.name}`}
                      role='button'
                      // eslint-disable-next-line react/no-unknown-property
                      data-testid='sidebar_menu_link'
                      onClick={handleShowMenu}
                      onKeyDown={handleShowMenu}
                    >
                      {taxon.name}
                      {taxon.dropDownItems && (
                        <span className={s.menuIcon}>
                          <DropDown />
                        </span>
                      )}
                    </span>
                  )}
                  {taxon.dropDownItems && (
                    <ul>
                      {taxon.dropDownItems && (
                        <li
                          onClick={closeMenu}
                          onKeyDown={closeMenu}
                          className={cn(s.menuItem, {
                            [s.isActive]:
                              openSubmenu === `${taxon.id}_${taxon.name}`,
                          })}
                        >
                          <Link
                            className={cn(s.link, {
                              [s.active]: pathname === taxon.href,
                            })}
                            href={taxon.href}
                          >
                            All items
                          </Link>
                        </li>
                      )}
                      {taxon.dropDownItems?.map((item: NormalizedMenuTaxon) => {
                        const { id, href, name } = item

                        if (name) {
                          return (
                            <li
                              key={id}
                              onClick={closeMenu}
                              onKeyDown={closeMenu}
                              className={cn(s.menuItem, {
                                [s.isActive]:
                                  openSubmenu === `${taxon.id}_${taxon.name}`,
                              })}
                            >
                              <Link
                                className={cn(s.link, {
                                  [s.active]: pathname === href,
                                })}
                                href={href}
                              >
                                {name}
                              </Link>
                            </li>
                          )
                        }

                        return ''
                      })}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className='sm:hidden mt-10 text-white text-sm leading-6'>
            <p>
              Customer service: <b>{data.attributes.contact_phone}</b>
            </p>
            <p className='font-bold'>
              {data.attributes.customer_support_email}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuSidebarView
