export const UserIconCopperStudio = ({ className }: { className?: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='28'
    className={className}
    viewBox='0 0 22 28'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.1071 20.28L21.4271 21.8C21.7304 23.1141 21.431 24.495 20.6107 25.5655C19.7904 26.636 18.5349 27.2843 17.1871 27.3333H4.81379C3.46602 27.2843 2.21054 26.636 1.39025 25.5655C0.569952 24.495 0.27054 23.1141 0.573785 21.8L0.893785 20.28C1.26183 18.2224 3.03061 16.7102 5.12045 16.6667H16.8805C18.9703 16.7102 20.7391 18.2224 21.1071 20.28ZM17.1871 25.32C17.8642 25.3121 18.5013 24.9985 18.9205 24.4667V24.48C19.4347 23.8349 19.6351 22.9944 19.4671 22.1867L19.1471 20.6667C18.9696 19.5402 18.02 18.6968 16.8805 18.6533H5.12046C3.98094 18.6968 3.03134 19.5402 2.85379 20.6667L2.53379 22.1867C2.37 22.9901 2.57014 23.8248 3.08046 24.4667C3.49957 24.9985 4.13674 25.3121 4.81379 25.32H17.1871Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6671 14H10.3338C7.38825 14 5.00043 11.6122 5.00043 8.66665V5.14665C4.99688 3.95739 5.46773 2.81582 6.30867 1.97488C7.1496 1.13395 8.29118 0.663091 9.48043 0.666646H12.5204C13.7097 0.663091 14.8513 1.13395 15.6922 1.97488C16.5331 2.81582 17.004 3.95739 17.0004 5.14665V8.66665C17.0004 11.6122 14.6126 14 11.6671 14ZM9.48043 2.66665C8.11077 2.66665 7.00043 3.77698 7.00043 5.14665V8.66665C7.00043 10.5076 8.49282 12 10.3338 12H11.6671C13.508 12 15.0004 10.5076 15.0004 8.66665V5.14665C15.0004 4.48891 14.7391 3.85811 14.2741 3.39302C13.809 2.92793 13.1782 2.66665 12.5204 2.66665H9.48043Z'
      fill='currentColor'
    />
  </svg>
)
