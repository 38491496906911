export const UserIconSecond = ({ ...props }) => (
  <svg
    role='img'
    width='28'
    height='28'
    viewBox='0 0 32 32'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>User Avatar Icon</title>
    <mask
      id='mask0_5909_34548'
      style={{
        maskType: 'alpha',
      }}
      maskUnits='userSpaceOnUse'
      x={0}
      y={0}
      width={32}
      height={32}
    >
      <circle cx={16} cy={16} r={16} fill='#C4C4C4' />
    </mask>
    <g mask='url(#mask0_5909_34548)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 29.5385C19.8086 29.5385 23.2499 27.9658 25.7099 25.4344C23.8285 22.0173 20.1958 19.7002 16.0212 19.6923C16.0141
        19.6924 16.0071 19.6924 16 19.6924C15.9929 19.6924 15.9859 19.6924 15.9788 19.6923C11.8042 19.7002 8.17148 22.0173
        6.29007 25.4344C8.75007 27.9658 12.1914 29.5385 16 29.5385ZM11.3548 18.0487C8.58438 19.0607 6.23718 20.9519 4.651
        23.3846C3.26627 21.2609 2.46154 18.7244 2.46154 16C2.46154 8.52291 8.52291 2.46154 16 2.46154C23.4771 2.46154
        29.5385 8.52291 29.5385 16C29.5385 18.7244 28.7337 21.2609 27.349 23.3846C25.7628 20.9519 23.4156 19.0607 20.6452
        18.0487C22.3163 16.6948 23.3846 14.6261 23.3846 12.3078C23.3846 8.22935 20.0784 4.92314 16 4.92314C11.9216 4.92314
        8.61539 8.22935 8.61539 12.3078C8.61539 14.6261 9.68367 16.6949 11.3548 18.0487ZM16.0191 17.2308C16.0128 17.2308
        16.0064 17.2308 16 17.2308C15.9936 17.2308 15.9873 17.2308 15.9809 17.2308C13.2707 17.2205 11.0769 15.0203 11.0769
        12.3078C11.0769 9.58882 13.2811 7.38468 16 7.38468C18.7189 7.38468 20.9231 9.58882 20.9231 12.3078C20.9231 15.0203
        18.7293 17.2205 16.0191 17.2308ZM32 16C32 19.7121 30.7358 23.129 28.6145 25.8436C29.2111 27.3701 29.5385 29.0314
        29.5385 30.7692C29.5385 38.2463 23.4771 44.3077 16 44.3077C8.52292 44.3077 2.46154 38.2463 2.46154 30.7692C2.46154
        29.0315 2.78896 27.3701 3.38548 25.8436C1.26417 23.129 0 19.7121 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344
        32 16ZM16 32C20.1142 32 23.8658 30.4471 26.7005 27.8955C26.946 28.812 27.0769 29.7753 27.0769 30.7692C27.0769 36.8869
        22.1176 41.8462 16 41.8462C9.88239 41.8462 4.92308 36.8869 4.92308 30.7692C4.92308 29.7753 5.05399 28.812 5.2995
        27.8955C8.13422 30.4471 11.8858 32 16 32Z'
        fill='black'
      />
    </g>
  </svg>
)
