import React from 'react'

export const LoupeIconThird = ({ ...props }) => (
  <svg
    role='img'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Search Icon</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6506 3C16.4205 3 20.3004 6.92543 20.3004 11.7513C20.3004 14.0282 19.4367 16.1048 18.0235 17.6633L20.8044 20.4709C21.0646 20.7343 21.0655 21.1602 20.8053 21.4235C20.6756 21.5565 20.5041 21.6222 20.3336 21.6222C20.1639 21.6222 19.9934 21.5565 19.8628 21.4253L17.0484 18.5858C15.5679 19.7854 13.6906 20.5036 11.6506 20.5036C6.88075 20.5036 3 16.5772 3 11.7513C3 6.92543 6.88075 3 11.6506 3ZM11.6506 4.34802C7.61533 4.34802 4.33237 7.66864 4.33237 11.7513C4.33237 15.834 7.61533 19.1555 11.6506 19.1555C15.685 19.1555 18.968 15.834 18.968 11.7513C18.968 7.66864 15.685 4.34802 11.6506 4.34802Z'
      fill='currentColor'
    />
  </svg>
)
