export const LoupeIconSecond = ({ ...props }) => (
  <svg
    role='img'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Search Icon</title>
    <path
      d='M21.2 23.0667L13.7333 15.6C13.0667 16.1333 12.3 16.5556 11.4333 16.8667C10.5667 17.1778 9.64444 17.3333 8.66667 17.3333C6.24444
      17.3333 4.19467 16.4947 2.51733 14.8173C0.839111 13.1391 0 11.0889 0 8.66667C0 6.24444 0.839111 4.19422 2.51733 2.516C4.19467 0.838667
      6.24444 0 8.66667 0C11.0889 0 13.1391 0.838667 14.8173 2.516C16.4947 4.19422 17.3333 6.24444 17.3333 8.66667C17.3333 9.64444 17.1778
      10.5667 16.8667 11.4333C16.5556 12.3 16.1333 13.0667 15.6 13.7333L23.1 21.2333C23.3444 21.4778 23.4667 21.7778 23.4667 22.1333C23.4667
      22.4889 23.3333 22.8 23.0667 23.0667C22.8222 23.3111 22.5111 23.4333 22.1333 23.4333C21.7556 23.4333 21.4444 23.3111 21.2 23.0667ZM8.66667
      14.6667C10.3333 14.6667 11.7502 14.0836 12.9173 12.9173C14.0836 11.7502 14.6667 10.3333 14.6667 8.66667C14.6667 7 14.0836 5.58311 12.9173
      4.416C11.7502 3.24978 10.3333 2.66667 8.66667 2.66667C7 2.66667 5.58311 3.24978 4.416 4.416C3.24978 5.58311 2.66667 7 2.66667 8.66667C2.66667
      10.3333 3.24978 11.7502 4.416 12.9173C5.58311 14.0836 7 14.6667 8.66667 14.6667Z'
      fill='currentColor'
    />
  </svg>
)
